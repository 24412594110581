.ai-dashboard {
    position: relative; // Ensure proper positioning

    .ai-dashboard-container {
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .top-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 20px;
            }

            .filters {
                display: flex;
                align-items: center;
                gap: 10px;

                .filter-label {
                    font-size: 13px;
                    color: $title-color-2;
                    margin: 0;
                    white-space: nowrap; // Updated
                }

                .select-boxes {
                    display: grid;
                    grid-template-columns: 130px 190px 140px;
                    gap: 25px;

                    .input-black {
                        .label {
                            display: none;
                        }
                    }
                }
            }
        }

        .chart-container {
            display: grid;
            grid-template-columns: 35% auto;
            gap: 15px;
        }

        &.not-access {
            pointer-events: none; // Prevent interaction with the background
            filter: blur(5px); // Apply blur to the background
        }
    }

    .empty-state {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        .empty-state-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            p {
                font-size: 16px;
            }
        }

        .btn {
            width: max-content;
        }

        // }
    }
}