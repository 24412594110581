.my-content-preview {
    width: 100%;
    padding: 30px;

    .my-content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .my-content-left {
            display: flex;
            align-items: center;
            gap: 15px;

            .my-content-back-button {
                width: 20px;
            }
            .my-content-title {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .my-content-body {
        height: 600px;
        padding: 50px;

        .my-content-preview {
            height: 100%;
            display: flex;
            justify-content: center;
        }
    }
}