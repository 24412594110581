.statitics-wrap {
  padding-right: 45px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 10px;
  background-color: #ffffff;

  .statitics-header {
    padding: 30px 30px 20px;
    display: grid;
    grid-template-columns: 70% auto;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }

    .right-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;

      .select-container {
        width: 100%;

        display: grid;
        grid-template-columns: 55% 35%;
        justify-content: space-between;
      }

      .input-black{

        .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
          display: none;
        }
      }
    }
  }

  .axis {
    padding: 0 30px 20px;
    display: flex;
    justify-content: flex-end;
    gap: 3%;

    p {
      margin: 0;
      color: $gray-color;
      font-size: 14px;
    }
  }

  .no-bar-data{
    width: 100%;
    height: 300px;
    font-size: 22px;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}