.grid {
    padding: 20px;
    // width: 30%;
    flex: 0 0 30%;
    background: $gray-hover-color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &:hover {
        background: $gray-hover-color-1;
    }

    .grid-image {
        width: 100%;
        height: 192px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
            background-position: top;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .youtube-video {
            width: inherit;
            height: inherit;
        }

        .url-image-container {
            height: inherit;
            background-color: $button-background;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .grid-body {
        display: flex;
        justify-content: space-between;

        .grid-name {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .title-name {
                color: #1F1F1F;
                font-size: 14px;
                font-weight: 600;
            }

            .avatar {
                display: flex;
                gap: 15px;
                align-items: center;

                .avatar-img {
                    color: #FFFFFF;
                    background: #0277F4;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 50px;
                    padding: 7px 11px;
                }
            }
        }

        .grid-action {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}