  .dropDown-menus {

    .dropDown-menu-header {
      width: 212px;
      padding: 6px 16px;
      margin: 0 0 10px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5%;
      border-bottom: 1px solid $border-color;

      input {
        width: 180px;
        outline: none;
        border: none;
        border-bottom: 1px solid $border-color;

        font-size: 16px;
        font-weight: 600;
        color: $title-color;
      }

      p {
        margin: 0;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-size: 16px;
        font-weight: 600;
        color: $title-color;
      }

      span {
        cursor: pointer;
      }
    }

    .dropDown-menu-item {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }