.content-thumbnail {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        width: 28px;
        height: 28px;
        object-fit: cover;
        border-radius: 3px;
        transition: all .4s ease-in-out;
        cursor: pointer;

        &:hover {
            scale: 1.35;
        }
    }

    .default-image {
        object-fit: contain;
    }
}