.modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-header {
        // padding: 0px 0px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .modal-header-title {
            font-weight: 600;
            font-size: 16px;
            color: #1F1F1F;
        }

        .modal-close-button {
            width: 19px;
            height: 19px;
            cursor: pointer;
        }
    }

    .modal-body {

        // .form-button .btn button {
        //     font-size: 12px;
        // }

        .table {
            border-radius: 15px;
        }
    }

    .modal-header-title {
        display: flex;
        flex-direction: column;
        // text-align: center;
        gap: 8px;

        .modal-title {
            margin: 0;
            color: #1F1F1F;
            font-size: 18px;
            font-weight: 600;
            line-height: 2;
        }

        .modal-body-content {
            margin: 0;
            color: #1F1F1F;
            font-size: 14px;
            font-weight: 400;
        }

    }

    .modal-delete-buttons {
        display: flex;
        justify-content: end;
        gap: 20px;
        width: 100%;
        margin-top: 20px;

        .btn {
            width: 25%;
        }
    }
}