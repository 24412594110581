.content {
  .main-content {
    .content-area-subscription {
      height: 77vh;
      border-radius: 8px;
      padding-bottom: 20px;

      .my-content-cards {
        display: flex;
        gap: 20px;
        padding-bottom: 20px;

        .card {
          background-color: $white-color;
          width: 100%;
          border-radius: 8px;
          box-shadow: 0px 0px 9px $box-shadow-color;
          padding: 10px 20px 10px 10px;

          .footer-card {
            display: flex;
            flex-direction: row-reverse;

            .text-button {
              color: $paragraph-grey-color;
              font-size: 12px;
              cursor: pointer;
            }
          }

          .progress {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .progress-chat {
              display: flex;
              align-items: center;
              gap: 10px;
            }

            .progress-text {
              h2 {
                font-size: 13px;
                color: $store-size-color;
                font-weight: 500;
              }

              h3 {
                font-size: 14px;
                color: $title-color;
                font-weight: 500;
              }
            }

            .text-danger {
              font-weight: 400 !important;
            }
          }
        }
      }

      .my-content {
        background-color: $white-color;
        border-radius: 8px;
      }
    }
  }
}