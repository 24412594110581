.single-draggable-content-wrap {
  .single-draggable-content {
    padding: 12px;
    border: 0.75px solid $card-border-color;
    background-color: var(--draggingElementColor);
    border-radius: 6px;

    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap: 15px;
    cursor: grabbing;
    z-index: var(--draggingIndex);

    .content-thumbnail {
      width: 42px;
      height: 42px;
      object-fit: cover;
      border-radius: 5px;
    }

    .default {
      object-fit: contain;
    }

    .content-details {
      display: flex;
      flex-direction: column;
      gap: 5px;

      width: inherit;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      h1 {
        margin: 0;
        font-size: 13px;
        font-weight: 500;
        color: $title-color;
      }

      h5 {
        width: max-content;
        padding: 2px 4px;
        margin: 0;
        font-size: 10px;
        color: $paragraph-blue-color;
        background-color: $blue-background-color;
      }

      .content-types {
        display: flex;
        flex-wrap: wrap;
        row-gap: 3px;

        span {
          padding: 1px 5px;
          margin: 0;
          font-size: 10px;
          color: $paragraph-blue-color;
          background-color: $blue-background-color;
          border-right: 1px solid $paragraph-blue-color;

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}