.filter{
    width: 350px;
    padding: 20px;

    .filter-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
        border-bottom: 1px solid #ccc;

        .clear-all-button{
            color: #F19702;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
        }

        .filter-apply-button {
            .btn {
                button {
                    padding: 4px 12px;
                    font-size: 12px;
                    min-width: 0px;
                }
            }
        }
    }

    .filter-body{
        padding: 20px 0px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        height: 586px;
        overflow-y: scroll;

        .filter-body-session {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .filter-body-content {
                display: flex;
                flex-direction: column;
                gap: 20px;
                border-bottom: 1px solid #CACACA;
                padding-bottom: 15px;

                h1 {
                    font-size: 14px;
                    color: #1F1F1F;
                }

                .more-button{
                    color: #1A5EEC;
                    font-size: 14px;
                    cursor: pointer;
                    padding-left: 25px;
                }

                .css-ahj2mt-MuiTypography-root{
                    font-size: 13px;
                }
            }

            .input-text .input-black {
                .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
                    padding: 4.24px !important;
                }
            }
        }

        .form-field {
            .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
                padding: 7.5px 6px !important;
                font-size: 14px !important;
            }
        }
    }
}