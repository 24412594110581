.custom-layout-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $box-shadow-widget;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.layout-popup {
    background-color: $white-color;
    border-radius: 8px;
    width: 300px;
    padding: 20px;
    box-shadow: 0px 4px 8px $box-shadow-widget;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

.title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
}

.layout-content {
    margin-bottom: 20px;
    font-size: 14px;
    padding: 0px;

    .radio-input {
        accent-color: $success-button-background-color;
    }
}

.option {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 14px;

    input[type="radio"] {
        margin-right: 8px;
    }
}

.popup-footer {
    display: flex;
    justify-content: end;
}

.back-button {
    padding: 8px 16px;
    font-size: 14px;
    border: 1px solid $gray-light-color;
    border-radius: 4px;
    background-color: $white-color;
    cursor: pointer;
}

.continue-button {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    color: $white-color;
    background-color: $gray-light-color;
    cursor: not-allowed;

    &.enabled {
        background-color: $success-button-background-color;
        cursor: pointer;
    }
}