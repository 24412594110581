.player-update-wrap {
    width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .player-update-header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: $black-color;
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $label-color;
        }
    }

    .check-box-wrap {
        display: flex;
        justify-content: flex-end;
    }
}