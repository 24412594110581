.views {
  display: flex;
  padding: 10px 15px;
  .list-container {
    padding: 3px 10px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    border: solid #cacaca 0.2px;
    border-radius: 20px 0 0 20px;
    .list-icon {
      display: flex;
      justify-content: center;
      height: 20px;
      width: 20px;
      padding: 2px;
    }
  }
  .grid-container {
    cursor: pointer;
    padding: 3px 10px ;
    padding-top: 2px;
    justify-content: center;
    display: flex;
    border: solid #cacaca 0.2px;
    border-radius: 0 20px 20px 0;
    .grid-icon {
      padding-top: 3px;
      display: flex;
      justify-content: center;
      height: 20px;
      width: 20px;
    }
  }
  .active{
    background-color: #fafafa;
  }
}
