.select-input-box {
    padding: 20px;
    display: flex;
    border: 0.5px $card-border-color solid;
    border-radius: 5px;
    align-items: center;

    .list-chips {
        flex: 10;
        display: flex;
        align-items: center;
        .css-pl8gqh-MuiStack-root {
            display: flex;
            gap: 10px 0px;
            flex-wrap: wrap;
        }
    }

    .btn {
        flex: 1;
        button {
            font-size: 10px;
            padding: 5px;
            color: #1F1F1F;
            border: solid 0.2px #9b9a9a;
        }
    }
}

.muiltr-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Poppins" !important;
}