.scheduledStatus-wrap {
  height: max-content;

  display: flex;
  flex-direction: column;
  gap: 20px;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px;

  .scheduledStatus-header {
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .pieChart-wrap {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    gap: 30px;

    .pieChart-doNet {
      position: relative;
      width: inherit;
      height: 280px;

      .text-container {
        position: absolute;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
        z-index: 2;

        display: flex;
        flex-direction: column;
        gap: 12px;
        text-align: center;

        span {
          font-size: 14px;
          font-weight: 500;
          color: $paragraph-grey-color;

          &:last-child {
            font-size: 20px;
            font-weight: 600;
            color: $black-color;
          }
        }
      }
    }

    .legend {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      .each-legend {
        font-size: 14px;
        position: relative;
        display: flex;
        gap: 20px;

        .marker {
          position: absolute;
          top: 2px;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        .ar {
          position: absolute;
          top: 18px;
          right: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        .default-text {
          display: flex;
          align-items: center;
        }

        .default-text .name,
        .default-text .value {
          margin: 0;
          margin-left: 20px;
          font-size: 14px;
          margin-left: 20px;
          cursor: pointer;
        }

        .ar-text {
          display: flex;
          align-items: center;
        }

        .ar-text .name {
          margin: 0;
          font-size: 14px;
          margin-left: 10px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
}