.progress-bar-container {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .progress-bar-wrapper {
        flex: 1;

        .progress-details {
            display: flex;
            justify-content: space-between;
        }

        .progress-bar-text {
            margin-bottom: 4px;
            font-size: 12px;
            color: #666;

            .progress-bar-name {
                padding-right: 10px;
                color: #212B36;
                font-size: 14px;
            }
        }

        .progress-bar {
            height: 8px;
            border-radius: 5px;
            background-color: lightgray;
            overflow: hidden;
            position: relative;

            .progress-bar-fill {
                height: 100%;
                transition: width 0.3s ease;
            }
        }
    }

    .progress-bar-percentage {
        // margin-left: 8px;
        font-size: 12px;
    }
}

.custom-tooltips {
    background-color: white !important;
    /* White background */
    color: black !important;
    /* Black text */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    /* Optional box-shadow */
    font-size: 0.875rem;
    /* Adjust font size */
    padding: 10px;
    /* Padding inside the tooltip */
}

.custom-tooltip-arrow {
    display: none;
}

.content-tooltip {
    width: 100%;
}

.custom-content-title {
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;

    .content-status-view {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .content-list {
            display: flex;
            width: 100%;
            gap: 20px;
            justify-content: space-between;

            .content-details-list {
                display: flex;
                gap: 5px;
                flex-direction: column;

                .content-list-name {
                    color: #212B36;
                    font-weight: 500;
                    font-size: 14px;
                }

                .content-list-time {
                    font-size: 12px;
                    color: #717880;
                }
            }

            .content-details-status {
                display: flex;
                align-items: center;
                color: #30A84B;
                // font-weight: bold;
                font-size: 16px;
            }

            .content-details-close {
                display: flex;
                align-items: center;
            }
        }
    }
}

.schedule-status-details {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .device-count-details {
        display: flex;
        gap: 20px;

        span {
            font-size: 14px;
        }
    }
}

.device-received-status {
    color: #E8212E;
}