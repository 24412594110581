.layout-schedule-wrap {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  grid-template-rows: 90px calc(100vh - 90px);

  .layout-schedule-container {
    height: calc(100vh - 90px);
    display: grid;
    grid-template-columns: 300px auto;
  }

  .layout-wrap {
    height: calc(100vh - 90px);
    padding: 35px 25px;
    position: relative;
    background-color: $white-color;

    h1 {
      position: absolute;
      top: 15px;
      left: 25px;

      font-size: 13px;
      font-weight: 500;
      color: $title-color;
    }

    .layout-container {
      height: 100%;
      padding: 15px;
      background-color: $grey-background-color;
      border-radius: 5px;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(12, 1fr);
      gap: 15px;
    }
  }
}