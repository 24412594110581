.content-layout-header-wrap {
  width: 100vw;
  padding: 0 20px;
  border-bottom: 1px solid $grey-2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-layout-header-left {
    display: flex;
    align-items: center;
    gap: 30px;

    span {
      display: flex;
      cursor: pointer;
    }

    .layout-name-editor {
      display: flex;
      align-items: center;
      gap: 7px;

      h1 {
        font-size: 18px;
        color: $title-color;
        font-weight: 600;
      }

      input {
        width: 200px;
        outline: none;
        border: none;
        border-bottom: 1px solid $title-color;

        font-size: 18px;
        color: $title-color;
        font-weight: 600;
      }

      span {
        display: flex;
      }
    }
  }

  .btn {
    width: max-content;
  }
}