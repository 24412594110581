.logo {
    width: 60%;
    display: flex;

    img {
        width: 100%;
        height: 40%;
    }

}

@media only screen and (max-width: 1200px) {
    .logo {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .logo {
        width: 80%;
    }
}

@media only screen and (max-width: 450px) {
    .logo {
        padding: 5px;
        width: 400px;
    }
}