.template-file-format {
    width: 400px;
    // max-height: 80vh;
    padding: 25px 0 0 0;

    .form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .radio-button-wrap {
            padding-top: 10px;
        }
        
        .form-button-group {
            padding: 10px 0;
        }
    }
}