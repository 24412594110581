.droppable-wrap {
  height: inherit;

  .droppable-container {
    padding: 10px;
    width: 100%;
    height: 100%;

    background-color: $white-color;
    border: var(--droppable-border);
    border-radius: 2.5px;
    transition: all 0.1s ease-in-out;

    display: flex;
    flex-direction: column;

    .droppable-container-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .header-content {
        h5 {
          margin: 0;
          width: max-content;
          padding: 3px 5px;
          background-color: $grey-5;
          border-radius: 1px;
        }

        .accepted-types-container {
          margin-top: 7px;
          display: flex;
          gap: 4px;

          span {
            font-size: 11px;
            color: $title-color;
          }
        }
      }

      .header-edit-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        span {
          font-size: 13px;
          font-weight: 600;
          color: $success-text;
        }
      }
    }

    .dropped-contents-wrap {
      margin: 10px 0 0;
      width: 100%;
      height: 100%;
      overflow: scroll;

      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      align-content: flex-start;

      .dropped-content {
        position: relative;
        height: 130px;
        width: 130px;
        background-color: $grey-5;
        border-radius: 3px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .preview-with-icon {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 7px;


          .preview-icon {
            height: 24px;
            width: auto;
            object-fit: contain;
          }

          span {
            font-size: 14px;
            color: $title-color;
            font-weight: 400;

            width: inherit;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }

        .close-icon {
          height: 18px;
          width: 18px;
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #FFFFFF9e;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          cursor: pointer;
        }
      }
    }

    .dropped-gallery {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: 1px;
      align-items: center;
      justify-content: center;

      .gallery-image {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }

      span {
        font-size: 14px;
        color: $title-color;
        font-weight: 400;
      }
    }
  }

  .widget-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $white-color;
    border-radius: 2.5px;

    h5 {
      margin: 0;
      position: absolute;
      top: 10px;
      left: 10px;

      width: max-content;
      padding: 3px 5px;
      background-color: $grey-5;
      border-radius: 1px;
    }
  }
}

// Styles for edit Popup
.rearrange-wrap {
  width: 600px;

  .rearrange-table {
    width: 100%;
    padding: 10px 0;
  }

  .rearrange-button-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 25px;

    .btn {
      width: max-content;
    }
  }
}