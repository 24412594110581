.content {
    .main-content {
        .content-area {
            .my-content {
                .my-content-area-form {
                    .user-form-wrap {
                        display: grid;
                        grid-template-columns: 30% auto;
                        padding: 20px;
                        gap: 1rem;

                        .profile-upload {
                            height: max-content;
                            padding: 70px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                            border-radius: 8px;

                            .upload-click {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 1rem;
                                cursor: pointer;

                                img {
                                    width: 125px;
                                    height: 125px;
                                    object-fit: cover;
                                    border-radius: 50%;
                                }

                                p {
                                    margin: 0;
                                    font-size: 16px;
                                    color: $gray-color;
                                    text-align: center;

                                    span {
                                        color: #1A5EEC;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    .user-form {
                        padding: 30px 25px;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        gap: 1rem;
                        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                        border-radius: 8px;

                        .single-row {
                            display: grid;
                            grid-template-columns: 47% 47%;
                            justify-content: space-between;
                        }

                        .input-black {
                            .select-input-box {
                                .list-chips {
                                    flex: 4 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}