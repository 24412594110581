.floor-map-wrap {
  height: 100%;
  width: 100%;
  padding: 10px 20px;

  .floor-map-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .floor-header-left {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        cursor: pointer;
      }

      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: $title-color;
      }
    }

    .btn {
      width: max-content;

      button {
        min-width: 0;
      }
    }
  }

  .floor-map-container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px 20px 0;


    .image-marker {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .popup-wrap {
      width: 450px;
      background-color: $white-color;
      border-radius: 6px;
      padding: 0 0 10px 0;

      position: absolute;
      z-index: 2;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      // transition: all 0.7s ease-in-out;

      .popup-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 12px 16px 12px 12px;
        border-bottom: 0.5px solid $border-color-gray;

        h4 {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          color: $title-color;
        }

        span {
          cursor: pointer;
        }
      }

      .popup-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 10px;

        .device-details {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          row-gap: 20px;

          .single-detail {
            h5 {
              margin: 0;
              font-size: 12px;
              font-weight: 500;
              color: $title-color-2;
            }

            h6 {
              margin: 5px 0 0;
              font-size: 14px;
              font-weight: 500;
              color: $title-color;
            }
          }
        }

        .popup-button-wrap {
          display: flex;
          justify-content: flex-end;

          .btn {
            width: max-content;
          }
        }
      }
    }
  }
}