.subscription-renewal {
    width: 560px;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .message-title {
        font-size: 18px;
        color: $title-color;
    }

    .message-description {
        text-align: center;
        color: $paragraph-grey-color;
        font-size: 14px;
        line-height: 26px;

        p {
            margin: 0;
        }
    }
}