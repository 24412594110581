.dashboard-recent-activity {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .activity-container {
    padding: 10px 0 0 25px;
    max-height: 380px;
    overflow: scroll;

    display: flex;
    flex-direction: column;
    gap: 10px;

    .single-activity {
      position: relative;
      padding: 0 0 20px 20px;
      border-left: 0.5px solid $border-color;

      display: flex;
      flex-direction: column;
      gap: 10px;

      .absolute-container {
        position: absolute;
        top: 0;
        left: -6px;
        background-color: $white-color;

        width: 12px;
        height: 25px;

        .top-point {
          margin-top: 3px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $bullet-point-color;
        }
      }

      h2 {
        font-size: 14px;
        font-weight: 500;
        color: $title-color;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        color: $paragraph-grey-color;
      }
    }
  }
}

@media only screen and (max-width:1150px) and (min-width:450px) {
  .dashboard-recent-activity {
    width: 100%;
  }
}

@media screen and (max-width:450px) {
  .dashboard-recent-activity {
    width: 100%;

    h2 {
      font-size: 16px;
    }
  }
}