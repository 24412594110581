.dashboard-display-os {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px;

  .displayOs-header {
    display: flex;

    .header-left {
      display: flex;
      // align-items: center;
      width: 50%;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }

    }

    .header-right {

      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .Toggle-button {
        cursor: pointer;
        color: $gray-color;
        padding: 0px 20px 0 20px;
        display: flex;
        margin-top: 5px;
        gap: 15px;
        height: 40px;
        border-radius: 30px;
        align-items: center;
        background-color: $gray-hover-color;

        .active {
          cursor: pointer;
          color: $success-text;
          ;
        }
      }
    }
  }

  .pieChart-wrap {
    display: grid;
    grid-template-columns: calc(70% - 10px) calc(30% - 10px);
    gap: 20px;

    .pieChart-doNet {
      position: relative;
      width: inherit;
      height: 280px;

      .text-container {
        position: absolute;
        top: 52%;
        left: 52%;
        transform: translate(-50%, -50%);
        z-index: 2;

        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;

        span {
          font-size: 14px;
          font-weight: 500;
          color: $gray-color;

          &:first-child {
            font-size: 28px;
            font-weight: 600;
            color: $label-color;
          }
        }
      }
    }

    .legend {
      display: flex;
      justify-content: center;
      flex-direction: column;
      // width: 30%;
      gap: 10px;

      .each-legend {
        font-size: 14px;
        position: relative;
        display: flex;
        gap: 20px;

        .marker {
          position: absolute;
          top: 2px;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        .ar {
          position: absolute;
          top: 2px;
          right: 0px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        p {
          margin: 0;
          font-size: 14px;
          margin-left: 20px;
          cursor: pointer;
        }

        .arabic-label {
          margin-right: 20px;
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width:1150px) and (min-width:450px) {
  .dashboard-display-os {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .dashboard-display-os {
    width: 100%;

    .displayOs-header {
      .header-left h3 {
        font-size: 16px;
      }
    }

    .pieChart-wrap {
      .legend {
        .each-legend {
          .marker {
            left: -18px;
          }

          p {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 450px) {
//   .display-wrap {
//     box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
//     background-color: $white-color;
//     border-radius: 10px;
//     // width: 100vw;

//     .displayOs-header {
//       display: flex;
//       padding: 15px 25px 0;

//       .header-left {
//         display: flex;
//         width: 50%;
//         align-items: center;

//         h3 {
//           margin: 0;
//           font-size: 18px;
//           font-weight: 600;
//         }

//       }

//     }

//     .pieChart-wrap {
//       display: flex;
//       flex-direction: column;

//       .pieChart-doNet {
//         display: flex;

//         align-items: center;
//         padding-left: 40px;
//         width: 100%;
//         height: 280px;

//         .centralLabel {
//           font-size: 25px;
//           font-weight: 700;
//         }


//       }

//       .legend {
//         display: flex;
//         justify-content: center;
//         flex-direction: row;
//         width: 100%;
//         margin-bottom: 30px;
//       }
//     }
//   }
// }

// @media screen and (max-width:1000px) and (min-width:450px) {
//   .display-wrap {
//     box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
//     background-color: $white-color;
//     border-radius: 10px;
//     // width: 100vw;

//     .displayOs-header {
//       display: flex;
//       padding: 15px 25px 0;

//       .header-left {
//         display: flex;
//         width: 50%;
//         align-items: center;

//         h3 {
//           margin: 0;
//           font-size: 18px;
//           font-weight: 600;
//         }

//       }

//     }

//     .pieChart-wrap {
//       display: flex;
//       flex-direction: column;

//       .pieChart-doNet {
//         padding-left: 32px;
//         width: 100%;
//         height: 280px;
//         justify-content: center;
//         display: flex;


//         .centralLabel {
//           font-size: 25px;
//           font-weight: 700;
//         }

//       }

//       .legend {
//         display: flex;
//         justify-content: center;
//         flex-direction: row;
//         width: 100%;
//       }
//     }
//   }
// }