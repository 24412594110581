.outer {
    // width: 56px;
    // height: 56px;
    // border-radius: 50%;
    // border: 1px solid #0A7BCC;
    // padding: 6px;
    // position: relative;

    .inner {
        // width: 42px;
        // height: 42px;
        // border-radius: 50%;
        // border: 1px solid #0A7BCC;

        .svg-wrap {
            // position: absolute;
            // top: -0.3px;
            // left: 0;
            // z-index: 3;

            .circle-background {
                fill: none;
                stroke: #e5f4ff;
            }

            .circle-progress {
                fill: none;
                stroke: #0A7BCC;
                stroke-linecap: round;
                stroke-linecap: round;
            }

            .percent-text {
                font-weight: 600;
                color: $black-color;
                font-size: 12px;
            }
        }
    }
}