.saveAndTags-popup {
    width: 400px;
    // max-height: 80vh;
    padding: 30px 0 0 0;

    .form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-black .input-box {
            line-height: 1 !important;
            padding: 14px 9px !important;
        }
    }
}