.ai-device-table {
    .title {
        font-size: 14px;
        font-weight: 500;
    }
    .form-button-group {
        margin: 20px 20px 30px 0;
    }

}

.ai-device-player-column {
    display: flex;
    align-items: center;
    gap: 8px;

    .tooltip-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }
}

.ai-device-player {
    line-height: 0.9;

    .low-version {
        color: $error-border-color;
        border-bottom: 1px solid $error-border-color;
        cursor: pointer;
    }
}

.playerApp-version-update {
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        gap: 10px;
        padding: 30px 0 20px 0;
    }
}