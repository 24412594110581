.template-style-display-editor {
  width: 100%;

  .header {
    font-size: 15px;
    font-weight: 600;
    font-family: Poppins;
    margin: 0px;
  }

  .bgContainer {
    display: flex;
    justify-content: space-between;
    width: 25.5%;

    .bgTitle {
      // width: 18%;
      margin-left: 30px;
      font-size: 14px;
    }

    .bgColorBox {
      width: 40px;
      height: 25px;
      border: 1px solid $grey-2;
      border-radius: 2px;
      cursor: pointer;
      margin-top: 10px;
    }

    .bgPicker {
      position: absolute;
      top: 350px;
      left: 350px;

      .closeButton {
        text-align: center;
        border: 1px solid $grey-2;
        color: $error-border-color;
        cursor: pointer;
      }
    }

    .defaultButton {
      font-size: 13px;
      border: 0.5px solid $grey-2;
      border-radius: 5px;
      padding: 5px;
      height: 30px;
      margin-left: 35px;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  .container {
    margin: 0px 0px 30px 0px;
    width: 50%;

    .element {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        width: 30%;
        margin-left: 30px;
        font-size: 14px;
      }

      .colorBox {
        width: 40px;
        height: 25px;
        border: 1px solid $grey-2;
        border-radius: 2px;
        cursor: pointer;
        margin-left: 30px;
        margin-right: 0px;

      }

      .picker {
        position: absolute;
        top: 250px;
        left: 500px;
      }

      .selectBox {
        margin-right: 0px;
        margin-left: 0px;
        border: none;
        outline: none;
        font-size: 14px;
      }
    }
  }

  .imageContainer {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-evenly;

    .imageBox {
      margin: 0px;
      padding: 0px;
      width: 50px;
      height: 50px;
      background-color: $background-grey;
    }

    .icon {
      cursor: pointer;
    }
  }
}