.login {
  position: relative;

  .page {
    display: flex;
    align-items: center;
    // height: 100%;
    height: 100vh;

    .banner-wrap {
      height: 100vh;
      width: 50%;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .banner-container {
        width: 100vw;
        height: 100vh;

        .slick-slider {
          width: 50vw;
          height: 100vh;

          .login-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .forgot-password-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .form-session {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .login-forms {
        gap: 12px;
      }

      .forgot-login-forms {
        gap: 24px;
      }
     

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 451px;

        .title-container {
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 12px;
        }

        .loginTitle {
          margin: 0;
          font-size: 24px;
          color: #30A84B;
          font-weight: 600;
        }

        .loginTitle1 {
          margin: 0;
          color: $title-color;
          font-weight: 500;
          font-size: 18px;
        }

        .loginTitles {
          margin: 0;
          font-weight: 600;
          font-size: 24px;
          color: #30A84B;
        }

        .loginP {
          line-height: 24px;
          margin: 0px;
          font-size: 16px;
          color: #1F1F1F;
        }

        .reset-password-message {
          margin: 10px 0;
          width: 100%;
          padding: 15px 10px;

          display: flex;
          justify-content: center;
          gap: 15px;

          background-color: $success-table-header-background-color;
          border: 0.7px solid $success-text;
          border-radius: 5px;

          p {
            margin: 0;
            font-size: 14px;
            color: $success-text;
          }

          span {
            &:last-child {
              cursor: pointer;
            }
          }
        }

        .forget {
          font-weight: 400;
          font-size: 14px;
          line-height: 1em;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: -25px;
          margin-bottom: 15px;

          a {
            margin-top: 10%;
            float: right;
            color: #30A84B;
            font-weight: 500;
          }
        }

        .loginButton {
          width: 100%;
          border-top: 1px solid $gray-color;
          text-align: center;
          padding: 20px 0;

          a {
            width: 100%;
            font-size: 14px;
            color: $title-color;
            font-weight: 500;
          }
        }

        .have-an-account {
          margin-top: 3%;
          display: flex;
          justify-content: center;
          gap: 10px;
          .resend-email{
            color: $success-text;
            cursor: pointer;
          }
          .resend-email-disabled{
            color: $success-text;
            cursor: none ;
          }
          p {
            margin: 0;
            font-size: 16px;
            color: $black-color;
            font-weight: 500;
          }

          a {
            color: $success-text;
            font-weight: 500;
          }
        }

        .text-danger {
          text-align: center;
        }
        .otp-resend,
        .otp-error {
            display: flex;
            align-items: center;
        }
    
        .otp-resend {
            width: 100%;
            justify-content: flex-end;
        }
    
        .otp-error {
            justify-content: space-between;
        }
    
        .resend-otp {
            color: $success-text;
            cursor: pointer;
            font-weight: 500;
            font-size: 13px;
            padding-top: 10px;
        }
      }
    }
  }

  .language-wrap-absolute {
    position: absolute;
    top: 2%;
    right: 2%;

    display: flex;
    align-items: center;
  }

  .language-wrap-absolute-ar {
    position: absolute;
    top: 2%;
    left: 2%;

    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1068px) {
  .login {
    .page {
      .form-session {
        .form {
          width: 400px;
        }
      }
    }
  }
}

@media only screen and (max-width: 798px) {
  .login {
    .page {
      // padding-top: 100px;

      .banner {
        display: none;
      }

      .form-session {
        .form {
          width: 90%;

          .loginTitles {
            font-size: 20px;
          }

          .loginP {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 672px) {
  .login {
    .page {
      .form-session {
        .form {
          .loginTitles {
            font-size: 20px;
          }

          .loginP {
            font-size: 14px;
          }

          .loginTitle1 {
            font-size: 16px;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 650px) {
  .login {
    .page {
      .banner-wrap {
        display: none;
      }

      .form-session {
        width: 100%;

        .form {
          width: 90%;

          .loginTitles {
            font-size: 20px;
          }

          .loginP {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 418px) {
  .login {
    .page {
      .form-session {
        .form {
          width: 90%;
        }
      }
    }
  }
}