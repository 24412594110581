.validation-wrap {
    width: 27vw;
    min-width: 350px;

    .input-black {}

    .btn {
        margin-top: 5%;
    }

    .otp-resend,
    .otp-error {
        display: flex;
        align-items: center;
    }

    .otp-resend {
        justify-content: flex-end;
    }

    .otp-error {
        justify-content: space-between;
    }

    .resend-otp {
        color: $success-text;
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        padding-top: 10px;
    }

    .have-an-account {
        margin-top: 3%;
        display: flex;
        justify-content: center;
        gap: 10px;

        p {
            margin: 0;
            font-size: 16px;
            color: $black-color;
            font-weight: 500;
        }

        a {
            color: $success-text;
            font-weight: 500;
        }
    }
}

@media (max-width: 500px) {
    .validation-wrap {
        width: 80vw;
        min-width: 0px;

        .have-an-account {
            p {
                margin: 0;
                font-size: 14px;
            }
        }
    }
}