.cancel-subscription {
    width: 56vw;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 15px 0 0 0;

    .cancel-msg {
        font-size: 14px;
        color: $paragraph-grey-color;
        line-height: 25.2px;
    }
    .content-body {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .feedback-prompt {
            font-size: 14px;
            color: $title-color;
        }
    }
}