.transfer-credit {
    width: 20vw;
    // max-height: 80vh;
    padding: 30px 20px 20px 0;

    .form {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}