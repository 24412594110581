.settings-tabs-container {
  background-color: #fff;
  border-radius: 8px;
  // box-shadow: 0 0 9px #f0f0f0;
  display: grid;
  grid-template-rows: -webkit-max-content 1fr;
  grid-template-rows: max-content 1fr;
  height: 100%;
  padding: 20px 15px;

  .tabs-wrap {
    display: flex;

    .each-tab {
      position: relative;
      padding: 8px 15px 10px;
      cursor: pointer;

      .tab-text {
        display: flex;
        gap: 10px;

        h1 {
          font-size: 14px;
          font-weight: 500;
          color: var(--active-tab-color);
          transition: 0.2s all ease-in-out;
        }
      }

      .active-tab-border {
        width: var(--active-tab-width);
        height: 3px;
        background-color: $success-button-background-color;
        border-radius: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}