.loading {
  display: flex;
  gap: 5px;

  .loading-dot {
      display: inline-block;
      width: var(--dot-size, '6px');
      height: var(--dot-size, '6px');
      border-radius: 50%;
      background-color: var(--dot-blur-color, '#FFFFFF4e');
  }

  .loading-dot:nth-last-child(1) {
      animation: jumpingAnimation 1.2s 0.6s linear infinite;
  }

  .loading-dot:nth-last-child(2) {
      animation: jumpingAnimation 1.2s 0.3s linear infinite;
  }

  .loading-dot:nth-last-child(3) {
      animation: jumpingAnimation 1.2s 0s linear infinite;
  }


  @keyframes jumpingAnimation {
      0% {
          transform: translate(0, 0);
      }

      16% {
          transform: translate(0, -12px);
          background-color: var(--dot-active-color, '#FFFFFF');
      }

      33% {
          transform: translate(0, 0);
      }

      100% {
          transform: translate(0, 0);
          background-color: var(--dot-blur-color, '#FFFFFF4e');
      }
  }
}
