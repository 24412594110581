.sidebar-wrap {
    // margin: 12px 0 0;
    position: relative;
    width: 100%;
    min-width: 275px;
    max-width: 18vw;
    height: 92vh;

    // border-top: solid 1px #CACACA;
    border-right: 2.5px solid $box-shadow-color;
    transition: all 0.4s ease-in-out;

    .toggle-arrow-wrap {
        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 4%;
        right: -12px;

        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
        background-color: $white-color;
        z-index: 500;
        border-radius: 50%;

        opacity: var(--toggle-opacity, '1');
        transform: var(--rotate-arrow, 'rotate(0deg)');
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: $success-button-background-color;

            .toggle-arrow {
                display: none;
            }

            .toggle-arrow-hover {
                display: flex;
            }
        }

        .toggle-arrow {
            display: flex;
        }

        .toggle-arrow-hover {
            display: none;
        }
    }

    .toggle-arrow-wrap-ar {
        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 4%;
        left: -12px;

        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
        background-color: $white-color;
        z-index: 99;
        border-radius: 50%;

        opacity: var(--toggle-opacity, '1');
        transform: var(--rotate-arrow-ar, 'rotate(0deg)');
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: $success-button-background-color;

            .toggle-arrow {
                display: none;
            }

            .toggle-arrow-hover {
                display: flex;
            }
        }

        .toggle-arrow {
            display: flex;
        }

        .toggle-arrow-hover {
            display: none;
        }
    }

    &:hover {

        .toggle-arrow-wrap,
        .toggle-arrow-wrap-ar {
            opacity: 1;
        }
    }
}

.sidebar-menu-wrap {
    height: inherit;
    overflow-y: scroll !important;
    position: relative;

    .sidebar-main-menu {
        padding: 16px 16px;
        // border-bottom: solid 0.3px #CACACA;


        .accordion-wrap {
            display: grid;
            grid-template-columns: 95% auto;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            .accordion-content {
                padding: 0 0 0 9px;
                display: flex;
                align-items: center;
                gap: 16px;

                h1 {
                    margin: 0;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: $gray-color;
                }
            }

            .accordion {
                transform: var(--rotate-accordion, 'rotate(0deg');
                transition: all 0.4s ease-in-out;
            }
        }

        .sidebar-sub-menu {
            padding: 0 0 0 10px;
            height: 100%;
            display: flex;
            // padding: 12px 0px 0px 0px;
            flex-direction: column;
            overflow: hidden;
            transition: all 0.4s ease-in-out;

            .each-sub-menu {
                position: relative;
                padding: 14px 9px;

                a {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    cursor: pointer;

                    p {
                        margin: 0;
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--active-menu-color, $gray-color);
                        transition: all 0.4s ease-in-out;

                        position: relative;
                        z-index: 2;
                    }

                    .subMenu-point {
                        width: 4.5px;
                        height: 4.5px;
                        border-radius: 50%;
                        background-color: var(--active-menu-color, $gray-color);
                        transition: all 0.4s ease-in-out;

                        position: relative;
                        z-index: 2;
                    }
                }

                .absolute-background {
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    height: 100%;
                    width: var(--background-width, '0%');
                    border-radius: 5px;
                    background-color: $success-table-header-background-color;
                    transition: all .4s ease-in-out;
                }
            }
        }
    }

    .single-sub-menu {
        padding: 0px 16px;

        .each-sub-menu {
            position: relative;
            padding: 14px 9px;


            a {
                display: flex;
                align-items: center;
                gap: 16px;
                cursor: pointer;

                p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--active-menu-color, $gray-color);
                    transition: all 0.4s ease-in-out;

                    position: relative;
                    z-index: 2;
                }

                span {
                    display: flex;
                    position: relative;
                    z-index: 2;
                }
            }

            .absolute-background {
                position: absolute;
                top: 0%;
                left: 0%;
                height: 100%;
                width: var(--background-width, '0%');
                border-radius: 5px;
                background-color: $success-table-header-background-color;
                transition: all .4s ease-in-out;
            }
        }
    }

    .sidebar-subscription-renewal {
        position: absolute;
        margin-top: 30px;
        left: 50%;
        transform: translateX(-50%);

        .box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 225px;
            height: 192px;
            border-radius: 6px;
            padding: 20px;
            overflow: hidden;
            position: relative;
            color: white;
            background-color: $glass-black-background;

            &::before {
                content: "";
                position: absolute;
                top: -58%;
                left: -30%;
                width: 200%;
                height: 200%;
                width: 352px;
                height: 465px;
                background-image: url(../../assets/images/dashboard-bg.png);
                background-size: cover;
                background-position: center;
                transform: rotate(-35deg); // Adjust the rotation angle as needed
                z-index: -1;
            }

            .message {
                font-size: 14px;
                margin-left: 0;
                line-height: 25.2px;
                z-index: 1;
            }

            .form-button {
                z-index: 1;

                .btn .success-button {
                    padding: 6px 15px;
                    width: max-content;
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .sidebar-wrap {
        display: none;
    }

}