.content-view {
    padding: 5px 0 0;
    width: 400px;

    .content-file {
        width: inherit;
        height: 200px;
        background-color: $black-color;
        border-radius: 5px;
        overflow: hidden;

        .youtube-video {
            width: inherit;
            height: inherit;
        }

        video {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        iframe{
            width: 100%;
            height: 100%;
            border: none;
        }

        .pdf-icon-container {
            width: 100%;
            height: 100%;
            background-color: $button-background;

            display: flex;
            align-items: center;
            justify-content: center;

            .pdf-icon {
                height: 50%;
                object-fit: contain;
            }
        }
    }


    .content-details {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .content-detail {
            h2 {
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                color: $label-color;
            }

            p {
                margin: 5px 0 0;
                font-size: 14px;
                font-weight: 500;
                color: $black-color;
            }

            // a {
            //     margin: 5px 0 0;
            //     font-size: 14px;
            //     font-weight: 500;
            // }
        }
    }
}