.plans-pricing-wrap {
  max-width: 90vw;
  max-height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  .switch-tenure-wrap {
    margin: 3% 0;
    width: 35vw;
    border-radius: 4px;
    border: 0.5px solid $card-border-color;
    box-shadow: rgba(147, 147, 147, 0.2) 0px 2px 8px 0px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .each-tenure {
      height: 50px;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .tenure-active {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background-color: $success-table-header-background-color;
        border-radius: 4px;

        width: var(--active-width, '0%');
        height: var(--active-height, '0%');
        transition: 0.4s all ease-in-out;
      }

      p {
        margin: 0;
        position: relative;
        z-index: 3;

        font-size: 14px;
        color: var(--tenure-color, '#5C646D');
        transition: 0.4s all ease-in-out;
      }
    }
  }

  .plans-wrap {
    max-width: inherit;
    overflow: scroll;

    display: flex;
    padding: 3px 0;

    .each-plan {
      position: relative;
      margin: 0 0 0 10px;
      padding: 30px 15px 20px;
      width: calc((90vw/4) - 10px);
      min-width: 315px;

      border-radius: 8px;
      border: 0.5px solid $card-border-color;
      box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 6px 0px;

      display: grid;
      grid-template-rows: 1fr max-content;

      .plan-container {
        height: 100%;
        overflow-y: scroll;

        .plan-details {
          border-bottom: 0.5px solid $card-border-color;

          .description-button{
          border-bottom: 0.5px solid $card-border-color;
          }

          h1 {
            margin: 0;
            font-size: 20px;
            color: $title-color;
            font-weight: 600;

            position: sticky;
            top: 0;
            background-color: $white-color;
          }

          p {
            margin: 10px 0 0;
            font-weight: 400;
            line-height: 21px;
            padding-bottom: 10px;
            font-size: 14px;
            color: $title-color-2;
          }

          .price {
            margin: 25px 0;

            span {
              margin: 0;
              font-size: 24px;
              font-weight: 600;
              color: $title-color;

              &:last-child {
                font-size: 14px;
                font-weight: 400;
                color: $title-color-2;
              }
            }
          }
        }

        .features-wrap {
          h2 {
            margin: 20px 0 20px 20px;
            font-size: 14px;
            font-weight: 600;
            color: $title-color;
          }

          .features-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .each-feature {
              display: flex;
              gap: 5px;
              align-items: center;

              span {
                display: flex;
              }

              p {
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: $title-color;
              }
            }
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .btn {
        padding: 20px 0 0 0;

        button {}
      }
    }
  }
}