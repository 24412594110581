.success-wrap{
  width: 500px;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .success-img{
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  h2{
    font-size: 18px;
    color: $success-text;
    text-align: center;
  }
}