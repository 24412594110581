.calendar {

  .rbc-addons-dnd,
  .rbc-calendar {
    .calendar-toolbar {
      display: grid;
      grid-template-columns: 10% 10% 50% auto;
      gap: 1%;
      padding-bottom: 1%;
      padding-top: 5px;
      padding-left: 8px;
      background-color: $white-color;
      border-radius: 8px 8px 0 0;
      align-items: center;

      .calendar-toggle-menu {
        padding-top: 3px;
      }

      .toolbar-left {
        padding-left: 5%;

        .Today-button {
          border: 0ch;
          font-size: 14px;
          height: 30px;
          width: 70px;
          border-radius: 6px;
          color: white;
          background-color: #30a84b;
          cursor: pointer;
        }
      }

      .toolbar-center {
        display: flex;
        gap: 15px;
        justify-content: center;
        flex-direction: row;

        .prev-button,
        .next-button {
          all: unset;
          cursor: pointer;

          .icon {
            display: flex;
            justify-content: center;
            font-size: 20px;
            color: gray;
          }
        }

        .date-label {
          display: flex;
          justify-content: center;
          font-size: 14px;
          color: $black-color;
          padding-top: 3px;
          font-weight: 600;
        }
      }


      .toolbar-right {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        padding-right: 7%;

        .week-button,
        .day-button {
          border-radius: 3px;
          font-size: 14px;
          // height: 90%;
          border: 0cap;
          color: #8e8e8e;
          background-color: #fafafa;
          cursor: pointer;
          padding: 5px 15px;
        }

        .active {
          color: #30a84b;
          background-color: #f7fef8;
        }
      }

    }

    .rbc-time-view {
      border: #f7fbfe;
      background-color: white;

      .rbc-row {
        min-height: auto;
      }

      .rbc-allday-cell {
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 1px;
        }

        .rbc-row-bg {
          height: 100vmax;
        }

        .rbc-row-content {
          height: 60px;
        }
      }

      .rbc-event,
      .rbc-selected,
      .rbc-event-allday {
        font-weight: 500;
        border-radius: 0;
        color: #1c75bc;
        border-left: solid #1c75bc 5px;
        background-color: #f7fbfe;

        .event-title {
          font-size: 13px;
        }

        .event-time {
          font-size: 10px;
        }
      }

      .rbc-events-container {
        .event-title {
          font-size: 12px;

        }

        .event-time {
          padding-top: 8px;
          font-size: 10px;

        }
      }

      .rbc-day-slot .rbc-events-container .rbc-event {
        background-color: #f7fbfe;
        color: #30a84b;
        border-radius: 0;
        font-family: "Times New Roman", Times, serif;
        font-size: 12px;
        font-weight: 500;
        border: none !important;
        border-left: 5px solid #30a84b !important;

        &:focus,
        &:active {
          outline: none !important;
        }
      }

      .rbc-day-slot .rbc-events-container .special-event {
        background-color: #f7fbfe;
        color: #5030a8;
        border-radius: 0;
        font-family: "Times New Roman", Times, serif;
        font-size: 12px;
        font-weight: 500;
        border: none !important;
        border-left: 5px solid #304ea8 !important;

        &:focus,
        &:active {
          outline: none !important;
        }
      }

      .rbc-event.rbc-selected {
        background-color: #f7fbfe;
        border: none;
        border-radius: 0;
      }

      .rbc-event-allday.rbc-event {
        font-size: 12px;
        font-weight: 500;
        border-radius: 0;
        color: #1c75bc;
        border-left: solid #1c75bc 5px;
        background-color: #f7fbfe;
      }

      .rbc-time-header-cell .rbc-header {
        background-color: #fafafa;
        top: 50;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 50px;
        border: none;
      }


      .rbc-time-header.rbc-overflowing {
        border: none;
      }

      .rbc-time-content {
        border-top: 0.25px solid #CACACA;

        .rbc-time-gutter {
          font-size: 14px;
        }
      }

      .rbc-timeslot-group {
        min-height: 70px;
      }

      .rbc-event-label {
        display: none;
      }

      .rbc-label {
        display: flex;
        padding: 0 5px;
        justify-content: center;
        padding-top: 15px;
      }

      .rbc-today {
        background-color: white;
      }


    }
  }
}

