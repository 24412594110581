.my-content {
    display: flex;
    flex-direction: column;

    // gap: 20px;
    // padding-top: 20px;
    .tab-buttons {
        padding: 8px 28px 10px 8px;
    }

    .tab-bar {
        padding: 7px 28px 10px 7px;
        display: flex;
        justify-content: space-between;

        .tab-button {
            padding: 0px 25px;
            cursor: pointer;
        }

        .tab-bar-button {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            .tab-button {
                cursor: pointer;

                .tag-count {
                    background: $danger-text;
                    padding: 3px 8px;
                    color: $white-color;
                    font-size: 10px;
                    border-radius: 50%;
                    margin: -38px 14px;
                    display: flex;
                    justify-content: center;
                }
            }

            .tab-button-text {
                cursor: pointer;
            }
        }

        .tab-bar-list {
            display: flex;
            gap: 5vw;

            .menus {
                color: $gray-color;
                font-weight: 400;
                font-size: 16px;
                padding: 8px 0px;
                cursor: pointer;

                &.active {
                    color: #30A84B;
                    // border-bottom: #30A84B 2px solid;
                    // border-image: linear-gradient(to right, #fff 0%, #fff 20%, #30A84B 20%, #30A84B 80%, #fff 80%, #fff 100%) 100% 1;
                    // border-image: linear-gradient(to right, #30A84B 80%, transparent 50%) 100% 1;

                }
            }
        }

        p {
            margin: 0;
        }
    }

    .my-content-form {
        display: flex;
        gap: 100px;
        padding: 20px 0px 0px 40px;

        .input-black {
            width: 30%;
        }

        .profile-part {
            flex: 3;

            .upload-profile {
                display: flex;
                flex-direction: column;
                align-items: center;

                .img {
                    width: 203px;
                    height: 203px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .btn>button {
                color: #1A5EEC;
            }
        }

        .form-path {
            display: flex;
            flex: 4;
            gap: 16px;
            flex-direction: column;

            .input-black {
                width: 80%;
            }
        }
    }

    .my-content-area {
        // height: 64vh;
        // overflow-y: scroll;

        thead td {
            position: sticky;
            /* make the table heads sticky */
            top: -1px;
        }

        .grid-list {
            gap: 30px 42px;
        }

        .schedule-details {
            padding: 0px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .schedule-details-list {
                gap: 20px;
                display: flex;
                padding: 0px 0px 10px 0px;

                .image {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                    }
                }
            }

            .contents-view {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .contents-modal {
                    padding: 20px;
                    border: solid 1px $black-color;
                    border-radius: 10px;
                    width: 24%;
                    box-shadow: 10px 0px 9px 1px rgba(0, 0, 0, 0.1);

                    .contents-header h3 {
                        margin: 0px 0px 20px 0px;
                        padding: 0;
                    }

                    .contents-body {
                        height: 230px;
                        overflow-y: scroll;

                        ul li {
                            padding: 0px 0px 10px 0px;
                        }
                    }
                }
            }
        }
    }

    .my-content-area-form {
        // height: 60vh;

        thead td {
            position: sticky;
            /* make the table heads sticky */
            top: -1px;
        }

        .location-form {
            padding: 20px 30px;
            display: grid;
            gap: 24px 80px;

            .input-text-area {
                grid-column: 1 / span 2;
            }
        }

        .my-content-permissions {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;

            .my-content-permissions-list {
                padding: 5px 40px;
                height: 63vh;
                overflow-y: scroll;
                flex: 1;

                h3 {
                    font-size: 24px;
                    color: #1F1F1F;
                    font-weight: 500;
                    margin-top: 0px;
                }

                .permission-list {
                    .permission-lis-session {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                    }
                }
            }

        }
    }

    .my-content-area-form-1 {
        height: 69vh;
        overflow-y: scroll;

        thead td {
            position: sticky;
            /* make the table heads sticky */
            top: -1px;
        }
    }
}

.tab-button {
    padding: 0px 20px;
}

@media screen and (max-height: 777px) and (min-height: 560px) {
    .content-area .my-content .my-content-area-form .my-content-permissions .my-content-permissions-list {
        height: 53vh;
    }
}

@media screen and (min-height: 1080px) {
    .content-area .my-content .my-content-area-form .my-content-permissions .my-content-permissions-list {
        height: 67vh;
    }
}

@media screen and (max-height: 560px) {
    .content-area .my-content .my-content-area-form .my-content-permissions .my-content-permissions-list {
        height: 40vh;
    }
}