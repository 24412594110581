// @media screen and (max-width: 768px) {
//   .icon-right {
//     width: min-content;
//   }
//   .topic {
//     position: relative;
//     margin-left: 20% !important;
//   }
//   .left {
//     font-size: 10px !important;
//     padding: 0px !important;
//   }
//   .mobile-view {
//     width: 100% !important;
//   }
//   .platter {
//     width: 80%;
//   }
//   .special {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//   }
//   .background {
//     background-color: #88ad4d;
//     max-width: 90vw !important;
//     height: 227vh !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-left: 10% !important;
//   }
//   .container {
//     background-color: #201c1b;
//     padding: 20px;
//     width: 80%;
//     height: 95% !important;
//   }
//   .right {
//     margin-top: 0% !important;
//     font-size: 10px !important;
//     width: 50%;
//     margin-left: -24px;
//   }
// }

@font-face {
  font-family: 'Paytone One';
  src: url(../../../assets/fonts/paytone/PaytoneOne-Regular.ttf)format('truetype');
  font-weight: 900;
  font-style: normal;
}

.template-container-10 {
  padding: 0% 3%;

  .template-10-container {
    padding: 5% 10%;
    background-color: $template-10-green;

    .container {
      background-color: $template-10-white;

      .menu {
        padding: 4%;

        h3 {
          margin: 0;
        }

        p {
          margin: 0;
        }

        .iconRight {

          display: flex;

          .title {
            margin: 0%;
            display: flex;
            align-items: center;
            width: 50%;
            font-size: 40px;
            color: $template-10-lightGreen;

            h2 {
              margin: 0;
            }
          }

          .images-container {
            display: flex;
            justify-content: flex-end;
            width: 50%;

            .images {
              width: 176px;
              height: 184px;
            }
          }
        }

        .content-part {
          display: flex;
          flex-direction: column;
          gap: 80px;

          .special {
            display: flex;
            gap: 10%;

            .right {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 45%;

              .starter {
                h3 {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              .item {
                display: grid;
                grid-template-columns: 80% 16%;
                gap: 15px;

                .topicRight {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                }

                .price-value {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  p {
                    text-align: center;
                    padding: 3px;
                    color: $template-10-lightGreen;
                    border: solid $template-10-lightGreen 2px;
                  }
                }

              }

              .item1 {
                display: grid;
                grid-template-columns: 16% 80%;
                gap: 20px;

                .price-style {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  p {
                    text-align: center;
                    padding: 3px;
                    border: solid $template-10-lightGreen 2px;
                    color: $template-10-lightGreen;
                  }
                }

                .topic {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                }

                .price1 {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  p {
                    padding: 5px;
                    color: $template-10-lightGreen;
                    border: solid $template-10-lightGreen 2px;
                  }

                }
              }
            }

            .left {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 45%;

              .category-title {
                background-color: $template-10-lightGreen;
                text-align: center;
                padding: 7px;
              }

              .item {
                display: grid;
                grid-template-columns: 80% 16%;
                gap: 20px;

                .topic {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                }

                .price1 {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  p {
                    text-align: center;
                    padding: 3px;
                    color: $template-10-lightGreen;
                    border: solid $template-10-lightGreen 2px;
                  }

                }
              }
            }
          }

          .special {
            padding-top: 10px;

            .left {
              .platter {
                height: 354px;
              }
            }

            .category-title {
              background-color: $template-10-lightGreen;
              text-align: center;
              padding: 7px;
            }
          }
        }

        .email {
          display: flex;
          justify-content: flex-end;
          font-size: larger;
          font-weight: bold;
        }
      }
    }
  }
}