.template-container-11 {
    background-color: $pure-black;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;

    .inner-container {
        width: 100%;
        .airpods-template {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            gap: 7%;

            .left-side {
                display: flex;
                gap: 50px;
                align-items: center;

                .item-image {
                    width: 25vw;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .price-wrapper {
                    width: max-content;
                    padding-bottom: 30px;
                }
            }

            .item-list {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding: 10px;
            }
        }
    }
}