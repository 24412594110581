.device-schedule {
    p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: $label-color;
    }

    .status-color-details {
        display: flex;
        justify-content: flex-end;
        gap: 25px;

        .status-color-detail {
            margin: 20px 0;
            display: flex;
            align-items: center;
            gap: 5px;

            .ongoing {
                width: 16px;
                height: 16px;
                background-color: #FACC15;
            }

            .upcoming {
                width: 16px;
                height: 16px;
                background-color: #14B8A6;
            }

            span {
                font-size: 12px;
                font-weight: 500;
                color: $black-color;
            }
        }
    }

    .table-container {
        width: 900px;
    }
}

.signage-form-wrap {
    width: 30vw;

    .signage-form {
        padding: 25px 0 0;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.status-wrap {
    display: flex;
    align-items: center;
    gap: 10px;

    .status-ongoing {
        width: 17px;
        height: 12px;
        background-color: #14B8A6;
    }

    .status-upcoming {
        width: 17px;
        height: 12px;
        background-color: #FACC15;
    }
}

.device-status {
    display: flex;
    align-items: center;
    gap: 5px;

    .tooltip-container {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .status-view-icon {
        cursor: pointer;
    }
}

.device-info-wrap {
    width: 600px;

    .signage-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 25px 0 20px;

        .single-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 6%;
        }
    }
}

.device-stats-wrap {
    width: 500px;
    // height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .chart-labels {
        display: flex;
        gap: 25px;

        .each-label {
            display: flex;
            align-items: center;
            gap: 7px;

            .for-color {
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            p {
                font-size: 14px;
            }
        }
    }
}

.ota-update-popup {
    display: flex;
    align-items: center;
    gap: 10px;

    .device-count {
        display: flex;
        align-items: center;
        gap: 10px;

        padding-right: 10px;
        border-right: 2px solid #FFFFFF50;

        span {
            display: flex;
            cursor: pointer;
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
        }
    }

    span {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
}

.player-app-version {
    display: flex;
    align-items: center;
    gap: 10px;

    span {}

    .player-app-status {
        .each-status {
            display: flex;
            align-items: center;
            gap: 5px;
            border-radius: 3px;
            padding: 5px 10px;

            font-size: 10px;
        }

        .pending {
            color: $gray-color;
            background-color: $gray-hover-color;
        }

        .latest {
            color: $success-text;
            background-color: $success-table-header-background-color;
        }
    }
}

.activity-log-wrap {
    width: 70vw;
    height: 70vh;
    padding-top: 20px;

    .processing-wrap {
        width: inherit;
        height: inherit;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        h1 {
            font-size: 20px;
            font-weight: 600;
            color: $black-color;
        }

        p {
            margin: 10px 0;
            font-size: 16px;
            font-weight: 500;
            color: $black-color;
        }
    }

    .log-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        li {
            font-size: 12px;
            font-weight: 500;
        }
    }
}