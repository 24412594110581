.widget-addform {
    .main-content {
        .content-area {
            .my-content {
                .my-content-area-form {
                    .user-form {
                        padding: 10px 15px;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        gap: 1rem;
                        box-shadow: $widget-shadow-color 0px 1px 3px 0px, $widget-shadow 0px 0px 0px 1px;
                        border-radius: 8px;

                        .single-row {
                            display: flex;
                            gap: 45px;
                            // grid-template-columns: 35% 35%;
                            // justify-content: space-between;
                        }

                        .wrapper-color-picker {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .color-picker {
                                position: absolute;
                                top: 60px;
                                left: 0;
                                position: absolute;
                                background: #fff;
                                padding: 10px;
                                border: solid 1px #e3e1e1;
                                box-shadow: 1px 1px 3px #000;
                                border-radius: 8px;
                                z-index: 9;

                                .closeButton {
                                    text-align: center;
                                    border: 1px solid $grey-2;
                                    color: $error-border-color;
                                    cursor: pointer;
                                }
                            }


                            .color-picker {
                                font-size: 15px;
                                color: $label-color;
                            }

                            .color-picker-flex {
                                display: flex;
                                gap: 8px;
                                align-items: center;
                            }

                            .colorpicker-style {
                                display: flex;
                                align-items: center;
                                gap: 20px;
                                margin-top: 5px;

                                .colorpicker-container {
                                    width: 24px;
                                    height: 24px;
                                    border: 1px solid gray;
                                    border-radius: 2px;
                                    cursor: pointer;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}