.header {
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    padding: 0px 40px 0px 30px;
    background-color: $white-color;
    // box-shadow: 10px 0px 9px 1px rgba(0, 0, 0, 0.1);

    .left-navbar {
        height: inherit;

        display: flex;
        align-items: center;

        img {
            width: 180px;
            height: 70%;
            object-fit: contain;
        }
    }

    .right-navbar {
        display: flex;
        align-items: center;
        gap: 40px;

        .language-wrap {
            display: flex;
            align-items: center;
            gap: 0;

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .notification-icon {
            position: relative;
            width: 24px;
            height: 24px;
            cursor: pointer;

            .notification-count {
                width: 15px;
                height: 15px;
                background-color: $danger-text;
                border-radius: 50%;

                position: absolute;
                top: -7.5px;
                right: -7.5px;

                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 10px;
                    color: $white-color;
                    font-weight: 600;
                }
            }
        }

        .profile-button {
            display: flex;
            gap: 10px;
            align-items: center;

            .avatar-image {
                width: 35px;
                height: 35px;
                border-radius: 50px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .profile-wrap {
                    height: inherit;
                    width: inherit;
                    border-radius: 50%;
                    background-color: var(--profile-color, $success-text);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: flex;
                        color: $white-color;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .user-name {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .name {
                    font-size: 14px;
                    font-weight: 500;
                    color: #1F1F1F;
                }

                .role {
                    font-size: 14px;
                    font-weight: 400;
                    color: $gray-color;
                }
            }
        }

    }

    .hamburger-icon {
        display: none;
    }
}

@media (max-width: 768px) {
    .header {
        padding: 0px 15px 0px 22px;

        .left-navbar {
            img {
                width: 178px;
                height: 45%;
                object-fit: contain;
            }
        }

        .right-navbar {
            gap: 10px;

            .language-wrap {
                p {
                    display: none;
                }
            }

            .profile-button {

                .avatar-image {
                    display: none;

                    img {
                        display: none;
                    }
                }

                .user-name {
                    display: none;
                }
            }
        }

        .hamburger-icon {
            display: flex;
        }

    }

    .drawer-header {
        display: flex;
        justify-content: flex-end;
    }
}

// @media only screen and (min-width: 750px) {
//     .header {
//         .right-navbar {
//             .language-text {
//                 display: none;
//             }
//         }
//     }
// }

// @media only screen and (max-width: 750px) {
//     .header {
//         .right-navbar {
//             .language-text {
//                 display: flex;
//                 align-items: center;
//                 font-size: 12px;

//                 .language-ball {


//                     .menu-icon {
//                         height: 20px;
//                         width: 20px;
//                     }
//                 }
//             }

//             .language-wrap {
//                 display: none;
//             }

//             .profile-button {
//                 .user-name {
//                     .name {
//                         font-size: 12px;
//                         font-weight: 500;
//                         color: #1F1F1F;
//                     }

//                     .role {
//                         font-size: 12px;
//                         font-weight: 500;
//                         color: #1F1F1F;
//                     }
//                 }

//                 .avatar-image {
//                     width: 28px;
//                     height: 28px;
//                     border-radius: 50px;
//                     overflow: hidden;
//                 }
//             }
//         }
//     }

// }

// @media only screen and (min-width:1250px) {
//     .left-navbar {
//         display: none;
//     }
// }

// @media only screen and (max-width:1250px) {
//     .header {
//         justify-content: space-between;

//         .left-navbar {
//             height: 30px;
//         }
//     }
// }

// @media only screen and (max-width: 450px) {
//     .header {
//         position: fixed;
//         z-index: 9999;
//         top: 0;
//         left: 0;
//         width: 100%;
//         justify-content: flex-end;

//         .left-navbar {
//             display: none;
//         }

//         .right-navbar {
//             .language-text {
//                 display: none;
//             }

//             .language-wrap {}

//             .profile-button {
//                 .user-name {
//                     .name {
//                         font-size: 10px;
//                         font-weight: 500;
//                         color: #1F1F1F;
//                     }

//                     .role {
//                         font-size: 10px;
//                         font-weight: 500;
//                         color: #1F1F1F;
//                     }
//                 }
//             }
//         }
//     }

// }