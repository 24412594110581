.layout-header-wrap {
  width: 100vw;
  padding: 0 20px;
  border-bottom: 1px solid $border-color-gray;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .layout-header-left {
    display: flex;
    align-items: center;
    gap: 30px;

    span {
      display: flex;
      cursor: pointer;
    }

    h1 {
      font-size: 18px;
      color: $title-color;
      font-weight: 600;
    }
  }

  .btn {
    width: max-content;
  }
}