.toggle-button {
    .switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 18px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 34px;

            &:before {
                position: absolute;
                content: "";
                height: 14px;
                width: 14px;
                left: 2.5px;
                bottom: 2px;
                background-color: white;
                transition: 0.4s;
                border-radius: 50%;
            }
        }

        input:checked+.slider {
            background-color: $success-text;
        }

        input:checked+.slider:before {
            transform: translateX(17.5px);
        }

        &.round {
            .slider {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }
        }
    }
}