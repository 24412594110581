.top-content {
    .top-main {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        padding: 0px 0px 10px;

        .top-label {
            font-size: 16px;
            font-weight: 600;
            color: #1F1F1F;

            .title-text{
                gap: 15px;
                display: flex;
                align-items: center;

                span {
                    cursor: pointer;
                }

                img {
                    width: auto;
                    height: 10px;
                    transform: rotate(270deg);
                }
            }
        }

        .top-button {
            .btn button {
                font-size: 14px;
            }
        }
    }
}