.change-password {
    padding: 20px;

    .form-fields {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .form-fields-single-row {
            width: 47%;
        }

        .form-fields-row {
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;
        }
    }
}