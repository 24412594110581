.repeat-form {
    width: 530px;

    .repeat-form-header {
        .css-qfz70r-MuiFormGroup-root {
            gap: 60px;
        }
    }

    .repeat-form-body {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-field {
            width: 100%;

            .input-black{
                width: 60%;
            }
        }

        .form-field-1 {
            width: 60%;
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;
        }
    }

    .repeat-form-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        padding: 25px 0px;

        .form-button {
           min-width: none;
        }
    }
}

@media (min-width: 1200px) {
    .css-4jnixx-MuiStack-root > :not(style):not(style) {
      width: 100% !important;
    }
  }