.authenticate-popup {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
        font-size: 20px;
        font-weight: 600;
        color: $black-color;
    }

    .sub-title {
        font-size: 16px;
        color: $paragraph-grey-color;
    }

    .content-body {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin-top: 10px;

        .input-black .input-box {
            height: 81px;
        }

        .example-url {
            margin: 0px;
            padding: 0px;
            font-size: 13px;
            color: $paragraph-grey-color;
        }
    }
}