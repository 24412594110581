.notification-wrap {
  padding: 20px 10px;
  min-width: 580px;
  max-width: 40vw;
  background-color: $white-color;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: $black-color;
  }

  .notification-container {
    padding: 20px 0 20px;
    max-height: 40vh;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 2px;

    .single-notification {
      padding: 7.5px 5px;
      border-radius: 5px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--notification-color, $white-color);

      .profile-content-wrap {
        display: flex;
        align-items: center;
        gap: 10px;

        .profile-wrap {
          height: 33px;
          width: 33px;
          border-radius: 50%;
          background-color: var(--profile-color, $success-text);

          display: flex;
          align-items: center;
          justify-content: center;

          span {
            display: flex;
            color: $white-color;
            font-size: 13px;
            font-weight: 600;
          }
        }

        .content-wrap {
          display: flex;
          gap: 3px;

          h2 {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: $title-color;

            span {
              font-weight: 600;
            }
          }
        }
      }

      .time-wrap {
        span {
          font-size: 12px;
          font-weight: 500;
          color: $title-color-2;
        }
      }
    }

    .no-new-notifications {
      padding: 15px 0 10px;
      text-align: center;
      p {
        margin: 0;
        font-size: 14px;
        color: $paragraph-grey-color;
        font-weight: 500;
      }
    }
  }
}
