.ai-instance-summary {
    width: 35vw;
    display: flex;
    font-size: 13px;
    flex-direction: column;
    gap: 20px;
    margin-top: 25px;

    .header {
        display: flex;
        padding: 0;
        padding-top: 20px;
        height: auto !important;
        justify-content: flex-start;
        gap: 20px;
    }

    .device-change-content {
        .sass-summary {
            display: flex;
            flex-direction: column;
            gap: 15px;
            overflow: scroll;

            table {
                width: 100%;
                border: solid 0.3px $gray-light-color;
                border-radius: 5px;
                border-collapse: collapse;

                tr {

                    td,
                    th {
                        border-top: solid 0.3px $gray-light-color;
                        border-bottom: solid 0.3px $gray-light-color;
                        padding: 15px 10px;
                        text-align: left;

                        .table-header {
                            display: flex;
                            justify-content: space-between;

                            span {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .payment-mode {
                .payment-title {
                    font-size: 16px;
                }

                .form {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .radio-button-wrap {
                        padding-top: 10px;
                    }

                    .form-button-group {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1543px) {
    .ai-instance-summary {
        width: 55vw;
    }
}