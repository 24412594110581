.new-features-wrap {
  width: 550px;
  .new-features-header {
    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: $success-text;
    }

    .description {
      margin: 7px 0;
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        margin: 0;
        font-size: 14px;
        color: $black-color;
      }

      h6 {
        margin: 0;
        font-size: 12px;
        color: $gray-color;
        font-weight: 400;
      }
    }
  }

  .added-features-wrap {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .each-feature {
      list-style: circle;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $black-color;
    }
  }
}