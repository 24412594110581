.content {
    .main-content {
        .content-area {
            .my-content {
                .my-content-area-form-1 {
                    .location-form {
                        padding: 20px 30px;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;

                        .single-row {
                            display: grid;
                            grid-template-columns: 47% 47%;
                            justify-content: space-between;
                        }

                        .multi-image-upload {
                            grid-column: 1 / span 2;
                            display: flex;
                            align-items: flex-end;
                            gap: 20px;

                            .images-view {
                                display: flex;
                                gap: 10px;
                                flex-wrap: wrap;
                                max-width: 43vw;

                                .image-item {
                                    position: relative;
                                    width: 60px;
                                    height: 60px;

                                    .image-close-icon {
                                        position: absolute;
                                        top: 4px;
                                        right: 4px;
                                        z-index: 3;
                                        cursor: pointer;
                                    }

                                    .image-view {
                                        height: 100%;
                                        cursor: pointer;

                                        img {
                                            object-fit: cover;
                                        }
                                    }
                                }
                            }
                        }

                        .form-header {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            h2 {
                                margin: 0;
                                font-size: 18px;
                            }

                            .add-button {
                                font-size: 14px;
                                cursor: pointer;
                                color: $success-button-background-color;
                            }
                        }

                        .floor-map-list {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;

                            .floor-map-item {
                                width: 32.6%;
                                display: flex;
                                flex-direction: column;
                                border: solid 0.5px $card-border-color;
                                border-radius: 8px;
                                padding: 10px;
                                gap: 10px;

                                .top-session {
                                    display: flex;
                                    justify-content: space-between;

                                    .thumb-image {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }

                                .middle-session {
                                    font-size: 13px;
                                }

                                .footer-session {
                                    font-size: 10px;
                                    color: $title-color-2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-button-list {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    gap: 10px;

    .menu-button {
        width: 30px;
        height: 30px;
        display: flex;
        border-radius: 3px;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        img {
            width: 24px;
        }
    }

    .active {
        background-color: $button-background;
    }
}

.table-menu-button-list {
    padding: 2px 12px;
}

.store-grid-list {
    display: flex;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;

    .grid-item {
        // width: 23.5%;
        flex: 0 0 23.5%;
        display: flex;
        gap: 10px;
        flex-direction: column;
        border-radius: 6px;

        .grid-image-carousel {
            height: 225px;

            .image-carousel-session {
                height: 100%;

                .image-carousel {
                    height: 100%;

                    img {
                        border-radius: 7px;
                        object-fit: fill;
                    }
                }

                .button-group {
                    display: flex;
                    justify-content: space-between;
                    margin-top: -115px;
                    padding: 0px 15px;
                    opacity: 0;

                    .left-button,
                    .right-button {
                        color: #fff;
                        background-color: #000;
                        padding: 1px 5px;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }

                &:hover .button-group {
                    opacity: 1;
                }
            }
        }

        .upload-image-set {
            display: flex;
            height: 225px;
            flex-wrap: wrap;
            gap: 10px;
            flex-direction: column;
            align-content: center;
            align-items: center;
            border-radius: 9px;
            justify-content: center;
            background-color: $button-background;
            cursor: pointer;
        }

        .grid-content {
            .grid-content-header {
                display: flex;
                justify-content: space-between;

                .gird-title {
                    display: flex;
                    gap: 20px;
                    font-size: 16px;
                    font-weight: 400;

                    .tags {
                        background-color: $success-table-header-background-color;
                        color: $success-button-background-color;
                        padding: 2px 6px;
                        height: auto;
                    }
                }
            }

            .grid-content-footer {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .my-content .my-content-area-form-1 {
        .location-form {
            padding: 11px 20px;
            gap: 14px 26px;
        }
    }

    .content {
        .main-content {
            .content-area {
                .my-content {
                    .my-content-area-form-1 {
                        .location-form {
                            padding: 11px 20px;
                            gap: 14px 26px;
                        }
                    }
                }
            }
        }
    }
}