.template-container-6 {
  background-color: $pure-black;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;

  .inner-container {
    width: 100%;

    .d-mart-template {
      width: 100%;
      height: 100%;
      // padding: 0% 5%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .logo-image {
        position: absolute;
        top: 0px;

        img {
          width: 5vw;
        }
      }

      .item-list {
        display: flex;
        justify-content: space-between;
        width: 90%;

        .items {
          display: flex;
          align-items: center;
          width: 10%;

          .item-image {
            width: 100%;
          }
        }
      }

      .item-list .items span {
        font-size: 16px;
        /* default font size */
        color: rgb(0, 0, 0);
        font-family: Inter;
      }

      @media screen and (min-width: 1550px) {
        .item-list .items span {
          font-size: 20px;
        }
      }

      @media screen and (max-width: 1280px) {
        .item-list .items span {
          font-size: 25px;
        }
      }
    }
  }
}