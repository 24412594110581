.content-layout-add-wrap {
  display: grid;
  grid-template-rows: 90px calc(100vh - 90px);

  .content-layout-add-container {
    margin: 20px;
    height: calc(100vh - 130px);
    background-color: $grey-background-color;
    border-radius: 6px;

    .content-layout-grid {
      padding: 15px;
      height: calc(100vh - 130px);

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(12, 1fr);
      gap: 15px;

      .each-layout-wrap {
        position: relative;
        border-radius: 3px;
        background-color: $white-color;
        overflow: hidden;

        h5 {
          position: absolute;
          top: 10px;
          left: 15px;
          z-index: 2;
          font-size: 10px;
          font-weight: 500;
          color: $title-color;
          padding: 6px;

          margin: 0;
          width: max-content;
          background-color: $grey-5;
          border-radius: 1px;
        }

        .each-layout-add-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: flex;
          flex-direction: column;
          gap: 5px;
          cursor: pointer;

          span {
            display: flex;
            justify-content: center;
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $title-color;
          }
        }

        .file_type_wrap {
          padding: 45px 15px 10px;
          height: 100%;
          overflow-y: auto;

          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
          gap: 15px;

          .each_file_type {
            position: relative;
            height: 130px;
            width: 130px;
            background-color: $grey-5;
            border-radius: 3px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;

            .file_type_image {
              height: 42px;
              width: 42px;
              object-fit: contain;
            }

            p {
              margin: 0;
              font-size: 14px;
              font-weight: 400;
              color: $title-color;
            }

            .close-icon {
              position: absolute;
              top: 10px;
              right: 10px;
              cursor: pointer;
            }
          }

          .header-edit-wrap {
            position: absolute;
            top: 10px;
            right: 15px;
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;

            span {
              font-size: 13px;
              font-weight: 600;
              color: $success-text;
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .widget-wrap {
          position: relative;
          width: 100%;
          height: 100%;

          .absolute-wrap {
            position: absolute;
            top: 10px;
            right: 10px;

            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $white-color;

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}