.template-default-header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    align-items: center;
    padding: 0 20px;
    box-shadow: $box-shadow-lightBlack 0px 1px 2px 0px, $box-shadow-lightBlack 0px 1px 3px 1px;
    background-color: $white-color;
    width: 100%;
    top: 0;
    z-index: 999;

    .default-header-left-enable {
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: #fff;

        img {
            width: 30px;
            height: 30px;
        }

        span {
            font-size: 14px;
        }
    }

    .default-header-left {
        display: flex;
        flex-direction: column;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-color: #fff;
    }
}

.header-height {
    height: 60px;
}