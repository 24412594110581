.input {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .label {
        font-weight: 400;
        font-size: 14px;
        color: $title-color;
        display: flex;
        align-items: center;
        gap: 8px;

        .image {
            width: 19px;
            height: 19px;
        }
    }

    .input-box {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 2;
        letter-spacing: 0.00938em;
        width: 100%;
        padding: 7px 9px;
        border-radius: 5px;
        border: 0.5px solid $border-color;
    }

    .input-box-error {
        border: 0.5px solid $error-border-color;
    }

    .error-message {
        margin: 5px 0 0;
        color: $danger-text;
        font-size: 12px;
        font-weight: 500;
    }

    .password-view-icon {
        position: absolute;
        cursor: pointer;
        left: 90%;
        top: 34px;
        width: 23px;
    }

    .ar {
        position: absolute;
        cursor: pointer;
        right: 90%;
        top: 35px;
        width: 23px;
    }

    .input-inner-icon {
        display: flex;
        gap: 10px;
        padding: 0px 10px 0px 0px;

        .image {
            width: 17px;
            cursor: pointer;
        }

        .input-box {
            font-size: 13px;
            border: none;
            box-shadow: none;
        }

        .input-box:focus,
        .input-box:active {
            outline: none;
        }
    }
}

.input-increase-decrease {
    position: relative;

    .input-wrap {
        width: 100%;
        overflow: hidden;
        display: flex;

        .input-operator {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $border-color;
            user-select: none;
            cursor: pointer;

            &:nth-child(1) {
                border-radius: 3px 0px 0px 3px;
                border-right: none;
            }

            &:nth-child(3) {
                border-radius: 0px 3px 3px 0px;
                border-left: none;
            }
        }

        .input-box-symbol {
            border-radius: 0px;
        }

        input {
            text-align: center;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input:focus {
            outline: none;
        }
    }
}

.input-inner-symbol {
    position: relative;

    .input-symbol {
        margin: 0;
        color: $gray-color;
        position: absolute;
        top: 71%;
        left: 13px;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    .input-box-symbol {
        min-width: 50px;
        padding-left: 25px !important;
    }

    .input-box-symbol::-webkit-outer-spin-button,
    .input-box-symbol::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.input-inner-icon {
    display: flex;
    gap: 10px;
    border: 1px solid $gray-color;
    padding: 9px 11px;
    border-radius: 6px;

    .image {
        width: 17px;
    }

    .input-box {
        font-size: 13px;
        border: none;
        box-shadow: none;
    }

    .input-box:focus,
    .input-box:active {
        outline: none;
    }
}

.input-inner-icon-error {
    border: 1px solid $error-border-color;
}

.input-black {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    .label {
        font-weight: 500;
        font-size: 14px;
        color: $label-color;
        display: flex;
        align-items: center;
        gap: 2px;

        .image {
            width: 19px;
            height: 19px;
        }
    }

    .image-banner {
        background-color: $black-color;
        border-radius: 6px;
        overflow: hidden;

        position: relative;
        width: 100%;
        height: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .upload-image-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border: 1px $gray-color solid;
            border-style: dashed;
            height: 100%;
            background: #FAFAFA;
            flex-direction: column;
            cursor: pointer;

            label {
                color: $black-color;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;

                span {
                    color: $success-text;
                    text-decoration: underline;
                }
            }

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: $black-color;

                span {
                    font-weight: 400;
                }
            }
        }

        .content-image {
            object-fit: contain;
            flex: 9;
            height: 100%;
            border: 1.5px solid #0000004e;
        }

        .pdf-icon-container {
            width: 100%;
            height: 100%;
            background-color: $button-background;

            display: flex;
            align-items: center;
            justify-content: center;

            .pdf-icon {
                height: 70%;
                object-fit: contain;
            }
        }

        .remove-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white-color;
            border-radius: 50%;
            padding: 5px;

            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;

            img {
                width: 14px;
                height: 14px;
            }
        }
    }

    .icon-slider {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon-wrap {
            background-color: $success-text;
            height: 30px;
            width: 35px;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .input-box {
        font-weight: 400;
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0.00938em;
        width: 100%;
        padding: 7px 9px;
        border-radius: 6px;
        border: 0.5px solid $border-color;

        .input-hide {
            border: none;
        }

        .input-hide::placeholder {
            background-color: $gray-hover-color;
            color: $gray-color;
            letter-spacing: 0.00938em;
        }

        .input-hide:focus,
        .input-hide:active {
            outline: none;
        }

        .css-pl8gqh-MuiStack-root {
            display: flex;
            gap: 10px 0px !important;
            flex-wrap: wrap !important;
        }
    }

    .input-box-error {
        border: 0.5px solid $error-border-color;
    }

    .error-message {
        margin: 5px 0 0;
        color: $danger-text;
        font-size: 12px;
        font-weight: 500;
    }

    .input-box:focus-within {
        border: 2px solid $black-color;
    }

    .input-box::placeholder {
        color: $gray-color;
    }

    .password-view-icon {
        position: absolute;
        z-index: 999;
        cursor: pointer;
        right: 15px;
        top: 41px;
        width: 20px;
    }
}

.toggle-button {
    display: flex;
    align-items: center;

    .disable-switch,
    .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 23px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 34px;

            &:before {
                position: absolute;
                content: "";
                height: 17px;
                width: 17px;
                left: 2px;
                bottom: 3px;
                background-color: white;
                transition: .4s;
                border-radius: 50%;
            }
        }

        input:checked+.slider {
            background-color: $success-text;
        }

        input:checked+.slider:before {
            transform: translateX(26px);
        }

        &.round {
            .slider {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }
        }
    }
}

.radio-button-wrap {
    display: flex;
    gap: 15px;

    input {
        width: 16.67px;
        height: 16.67px;
        accent-color: $success-text;
        cursor: pointer;
    }

    label,
    span {
        font-size: 14px;
        font-weight: 500;
        color: var(--checkbox-label-color);
        cursor: pointer;
    }
}

.custom-select-container {
    width: 100%;

    select {
        background-color: transparent;
        padding: 8px;
        width: 100%;
        height: 43px;
        border-radius: 5px;
        border: 1px solid #CACACA;
        outline: none;

        option {
            z-index: -1;
            border-radius: 1px !important;
            background-color: #fff;

            &:focus {
                background-color: #000;
            }
        }
    }
}

.custom-select-container select option:checked {
    background-color: #fff;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #000 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px !important;
}

.input-box::placeholder {
    color: $gray-color !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgb(75 75 75) !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 10.24px !important;
}

input::-ms-reveal,
input::-ms-clear {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

@media screen and (max-width: 450px) {
    .input {
        .label {
            padding-top: 3px;
            padding-bottom: 5px;
        }
    }
}