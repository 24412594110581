.multi-content-view-wrap {
  margin: 10px 0 0;
  position: relative;
  width: 780px;
  height: 270px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  overflow: hidden;

  .multi-content-slider-wrap {
    width: 780px;
    height: 270px;

    .single-content-container {
      width: 780px;
      height: 270px;

      .single-content-image {
        width: 780px;
        height: 270px;
        object-fit: contain;
      }

      .single-content-video {
        width: 780px;
        height: 270px;
        object-fit: contain;
      }

      .pdf-icon-container {
        width: 780px;
        height: 270px;

        display: flex;
        align-items: center;
        justify-content: center;

        .single-content-pdf {
          width: 70px;
          height: 70px;
          object-fit: contain;
        }
      }
    }
  }

  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
  }

  .prev-btn {
    left: 2%;
  }

  .next-btn {
    right: 2%;
  }

  .current-slider-count {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 999;

    padding: 5px 10px;
    background-color: #0000005e;
    border-radius: 25px;

    span {
      color: $white-color;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .delete-icon-wrap {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #0000001e;
  }
}
