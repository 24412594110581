.table-header-right {
    display: flex;
    padding: 0px 20px;
    align-items: center;

    .csv-button-wrap {
        padding: 0px 8px;
    }

    .btn {
        button {
            min-height: 30px;
            font-size: 12px;
            color: $label-color;
            font-weight: 500;
        }
    }
}

.export-button-text {
    display: flex;
    align-items: center;
    gap: 5px;
}

.MuiTableRow-root.MuiTableRow-head.muiltr-1x7w72q-MuiTableRow-root{
    background-color: #F9FAFB;
}