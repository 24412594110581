.advance-settings-wrap-schedule {
  margin: 20px 0 0;
  width: 70vw;
  overflow: hidden !important;

  .advance-settings-bottom {
    padding: 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      font-size: 13px;
      font-weight: 600;
      color: $title-color;
    }

    .form-button-group {
      padding: 0;
    }
  }
}

.content-rotation-main-window {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content-rotation-title {
    .rotate-title {
      color: #5C646D;
      font-size: 14px;
    }
  }

  .content-rotation-message-info {
    color: #5C646D;
    font-size: 14px;
  }
}