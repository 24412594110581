.plan-switch-summary {
  position: relative;
  width: 45vw;
  min-width: 750px;
  max-height: 70vh;
  overflow-y: scroll;

  .loader-wrap {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: $white-color;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tooltip-container {
    display: flex;
    cursor: pointer;
  }

  .section-header {
    margin: 0;
    font-size: 13px;
    color: $title-color;
    font-weight: 600;
  }

  .active-subscription-wrap {
    margin: 3%;

    .active-subscription-details {
      margin: 2.5% 0 0;
      display: flex;
      justify-content: space-between;

      .subscription-detail {
        display: flex;
        gap: 5px;

        h4 {
          margin: 0;
          font-size: 13px;
          color: $title-color;
          font-weight: 400;
        }

        p {
          margin: 0;
          font-size: 13px;
          color: $title-color;
          font-weight: 600;
        }

        span {
          margin: 0;
          font-size: 13px;
          color: $title-color-2;
          font-weight: 400;
        }
      }
    }
  }

  .plan-switch-details {
    padding: 3%;
    border-radius: 3px;
    border: 0.5px solid $card-border-color;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .plan-details-wrap {
      .plan-details {
        margin: 2.5% 0 0;
        display: flex;
        justify-content: space-between;

        .plan-detail {
          display: flex;
          align-items: center;
          gap: 5px;

          h4 {
            margin: 0;
            font-size: 13px;
            color: $title-color;
            font-weight: 400;
          }

          p {
            margin: 0;
            font-size: 13px;
            color: $title-color;
            font-weight: 600;
          }
        }
      }

      .payment-summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;

        h4 {
          margin: 0;
          font-size: 13px;
          color: $title-color;
          font-weight: 400;
        }

        .price {
          display: flex;
          align-items: center;
          gap: 5px;

          p {
            margin: 0;
            font-size: 13px;
            color: $title-color;
            font-weight: 600;
          }

          .amount-credit {
            color: $success-text;
          }

          .amount-debit {
            color: $danger-text;
          }

        }
      }
    }

    .final-price {
      padding: 2.5% 0%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-top: 1px dashed $card-border-color;

      h4 {
        margin: 0;
        font-size: 13px;
        color: $title-color;
        font-weight: 400;
      }

      p {
        margin: 0;
        font-size: 13px;
        color: $title-color;
        font-weight: 600;
      }
    }
  }

  .payment-mode {
    .payment-title {
      font-size: 16px;
      color: $title-color;
      margin: 0;
    }

    .payment-options-wrap {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  &.width100 {
    width: 50vw;

    .active-subscription-wrap {
      padding: 15px 0px;
      margin: 3% 0px;
      .section-body {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .section-body-list {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 20px;

        .section-body-item {
          display: flex;
          gap: 20px;

          .item {
            flex: 1;
          }
          .item2 {
            flex: 2;
            font-weight: 600;
          }
        }
      }

      .section-header {
        font-size: 16px;
      }
      .rules-list {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 20px;
        .rule {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}

.pr-20 {
  padding-right: 20px;
}