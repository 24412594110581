.integration-invalid-token-popup {

    .content {
        width: 421px;
        height: 102px;
        display: flex;
        gap: 10px;
        align-items: center;

        .invalid-text {
            font-size: 14px;
        }
    }

    .form-button-group {
        padding: 0 !important;
    }
}