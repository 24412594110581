.device-wrap {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .device-header {
    padding: 30px 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;

      span{
        font-size: 12px;
        font-weight: 500;
        color: $gray-color;
      }
    }

    .device-btn {
      border: none;
      color: #fff;
      background-color: $blue-color;
      font-size: 14px;
      padding: 5px 12px;
      border-radius: 3px;
    }
  }

  .pie-chart-wrap {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    .pie-chart {
      height: 220px;
      
    }

    .legend {
      max-width: 170px;
      max-height: 200px;
      overflow-y: scroll;
      position: absolute;
      top: 25%;
      right: 10px;

      display: flex;
      flex-direction: column;
      gap: 10px;

      &::-webkit-scrollbar{
        display: none;
      }

      .each-legend{
        position: relative;
        display: flex;
        gap: 5px;

        .marker{
          position: absolute;
          top: 4px;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        p{
          margin: 0;
          margin-left: 15px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .not-found{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
      font-size: 22px;
      font-weight: 600;
    }
  }
}