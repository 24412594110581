.template-view {
    overflow-y: scroll;
    width: 100%;
    padding: 12px 22px;

    .template-top {
        height: 55px;
        display: flex;
        align-items: center;
        padding: 0px 0px 10px;

        .template-title {
            font-weight: 600;
            font-size: 16px;
            color: #1F1F1F;
        }
    }

    .template-wrapper {
        height: 100%;
        background-color: $white-color;
        padding: 0px;
        border-radius: 8px;
        box-shadow: 0px 0px 9px #f0f0f0;

        .no-saved {
            text-align: center;
        }

        .template_tabs-container {
            display: flex;
            gap: 25px;
            padding-left: 5px;
            padding-bottom: 0px;
            margin-bottom: 0px;

            .tabs-content {
                color: $gray-color;
                margin: 0px;
                margin-top: 10px;
                padding: 0px;
                cursor: pointer;
            }

            .tabs-content-chosen {
                color: $success-text;
                margin: 0px;
                margin-top: 10px;
                padding: 0px;

            }

            .template-underline-bar {
                width: inherit;
                margin-top: 5px;
                padding-top: 0px;
            }

            .template-underline-bar-chosen {
                width: inherit;
                border: 1px solid $success-text;
                margin-top: 5px;
                padding-top: 0px;
            }
        }

        .template-scroll {
            height: 75vh;
            margin: 10px;
            overflow-y: scroll;
        }

        .category-container {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 20px;
            margin-top: 30px;

            .category-list {
                display: flex;
                width: inherit;
                margin: 0px;
                padding: 5px;
                height: inherit;
                border: 1px solid $gray-color;
                border-radius: 5px;
                cursor: pointer;

                .category-content {
                    margin: 0px;
                    padding: 0px 0px 0px 0px;
                    text-align: center;
                    color: $gray-color;
                    font-size: 13px;
                }
            }

            .category-list-chosen {
                display: flex;
                width: inherit;
                margin: 0px;
                padding: 5px;
                height: inherit;
                border: 1px solid $gray-color;
                border-radius: 5px;
                background-color: $success-text;
                cursor: pointer;

                .category-content {
                    padding: 0px 0px 0px 0px;
                    margin: 0px;
                    text-align: center;
                    color: $white-color;
                    font-size: 13px;

                }

            }
        }

        .category-title {
            margin-top: 30px;
            margin-left: 5px;
            font-weight: 600;
        }

        .template-container {
            overflow: hidden;
            .slick-list{
                .slick-slide {
                    margin-right: 20px;
                    width: max-content !important;
                    &.slick-current {
                        width: max-content !important;
                    }

                    &.slick-active {
                        margin-right: 20px;
                        width: max-content !important;
                    }
                }
            }
            .slick-list-custom {

                .slick-slide {
                    margin-right: 20px;
                    width: max-content !important;
                    &.slick-current {
                        width: max-content !important;
                    }

                    &.slick-active {
                        margin-right: 20px;
                        width: max-content !important;
                    }

                }
            }
        }

        .slick-slide img {
            gap: 0px;
            padding: 0px;
            margin-left: 0px;
            margin-right: 0px;
            width: 210px;
            height: 140px;
            object-fit: fill;
            cursor: pointer;

            .slick-arrow {
                background-color: $success-text;
            }
        }

        .saved-templates {
            padding: 18px;

            .search-template {

                .search-input-wrap {
                    width: 30%;
                }
                margin-bottom: 25px;

                .input-black {
                    width: 35% !important;

                    .input-box {
                        padding: 7px 9px 7px 40px;
                    }
                }
            }

            .s_template-list {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                padding: 10px;
                justify-content: flex-start;

                .s_template-grid {
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 23%;
                    gap: 10px;

                    .s_template-grid-img {
                        padding: 10px;
                        box-shadow: 0px 1.08px 8.67px 1.08px $template-black;
                        border-radius: 5px;
                        background-color: $white-color;

                        .images {
                            width: 100%;
                            height: 170px;
                            background-color: $black-color;
                        }
                    }

                    .s_template-footer {
                        display: flex;
                        gap: 15px;
                        flex-direction: column;

                        .avatar {
                            display: flex;
                            justify-content: space-between;
                            gap: 15px;
                            align-items: center;

                            .avatar-img-tx {
                                background-color: $template-avatar-blue;
                            }

                            p {
                                margin: 0;
                                max-width: 200px;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .dropDown-input-container {
                            display: flex;
                        }

                        .Edit-dropDown {
                            margin-left: '20px';
                            cursor: 'pointer';
                        }

                        .Delete-dropDown {
                            margin-left: '20px';
                            cursor: 'pointer';
                        }

                        .created-date {
                            display: flex;
                            gap: 15px;

                            span {
                                color: $gray-color;
                                font-size: 14px;

                            }
                        }
                    }
                }

                .s_template-image-container {
                    // flex: 1 1 200px;
                    background-color: $white-color;
                    border-radius: 8px;
                    box-shadow: 0 0 10px $box-shadow-black;

                    .s-image-wrapper {
                        padding: 8px;

                        .s_template-image {
                            width: 230px;
                            height: 140px;
                        }

                        .tem-name {
                            text-align: center;
                            margin: 5px;

                        }
                    }
                }

            }
        }

        // .custom-board-container {
        //     width: 100%;
        //     height: 30vh;
        //     background: linear-gradient(98deg, $box-shadow-green 0%, $box-shadow-green 90%);
        //     margin-top: 4%;

        //     .custom-board-content {
        //         float: right;
        //         width: 20px;
        //         height: 20px;
        //         color: $gray-color;
        //         margin: 10px;

        //     }

        //     .custom-board-content-container {
        //         display: flex;
        //         flex-direction: column;
        //         width: inherit;
        //         margin-left: 3%;

        //         .top-content {
        //             font-size: 45px;
        //             color: $white-color;
        //             font-weight: 500;
        //             margin: 0px;
        //         }

        //         .mid-content {
        //             color: $white-color;
        //             font-weight: 400;
        //             font-size: 15px;
        //         }

        //         .mail-icon-container {
        //             display: flex;
        //             color: $white-color;
        //             align-items: center;
        //             width: 20%;
        //             height: 20px;

        //             .mail-icon {
        //                 width: 30px;
        //                 height: 30px;
        //                 color: $white-color;
        //             }
        //         }
        //     }


        //     .content-board-image-container {
        //         width: 40%;
        //         height: 250px;
        //         object-fit: fill;
        //         margin: auto;
        //     }
        // }
    }
}