.dashboard-schedule-wrap {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 10px;
  background-color: #ffffff;

  .schedules-header {
    padding: 30px;
    display: grid;
    grid-template-columns: 80% auto;

    .header-left {

      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0;
        font-size: 14px;
        padding-top: 10px;
        color: $gray-color;
        font-weight: 200 !important;
      }
    }

    .header-right {}
  }

  .header-right .tab-bar-button {
    cursor: pointer;
    width: 20px;
    transform: rotate(180deg);
    float: right;
  }

  .schedule-table {
    overflow: scroll;
  }
}

@media only screen and (max-width:1040px) and (min-width:750px) {
  .dashboard-schedule-wrap {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .dashboard-schedule-wrap {
    .schedules-header {
      padding: 22px;
      .header-left h2 {
        font-size: 16px;
      }
    }
  }
}