.storage-stats-container {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 10px;
  background-color: #ffffff;

  .storage-stats-header {
    padding: 30px;

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .pie-chart-wrap {
    display: flex;
    flex-direction: column;

    .pie-chart {
      padding-top: 20px;
      display: flex;
      height: 200px;
    }

    .legend {
      padding-bottom: 20px;
      padding-right: 20px;

      .each-legend {
        padding: 8px 0 0 20px;

        .image-container {
          display: grid;
          grid-template-columns: 10% auto 20%;
          gap: 10px;

          .image-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .file-name {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 5px;

            .file-count {
              font-size: 12px;
              color: $gray-color;

            }

            .file-size {
              font-weight: 800;
              display: flex;
              justify-content: flex-end;
            }
          }

          .icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 40px;
            height: 40px;
          }

        }

      }
    }
  }
}

@media screen and (max-width:450px) {
  .storage-stats-container {
    .storage-stats-header h3 {
      font-size: 16px;
    }

    .pie-chart-wrap {
      .legend {
        .each-legend {
          .image-container {
            .icon {
              width: 30px;
            }
          }
        }
      }
    }
  }
}