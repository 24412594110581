.display-information {
    width: 30vw;
    min-width: 550px;
    padding: 20px 0px 0px;

    .display-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;

        .field-price {
            color: $border-color-gray;
            padding: 20px 0px 0px 0px;
        }
    }

    .down-message {
        padding: 20px 0px 10px;
        color: $danger-text;
        font-size: 14px;
        line-height: 1.5;
    }
}

.device-change-summary {
    width: 35vw;
    display: flex;
    font-size: 13px;
    flex-direction: column;
    gap: 20px;

    .header {
        display: flex;
        padding: 0;
        padding-top: 20px;
        height: auto !important;
        justify-content: flex-start;
        gap: 20px;
    }

    .payment-summary {
        .title {
            font-size: 14px;
            font-weight: 600;
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .device-change-content {
        .sass-summary {
            overflow: scroll;

            table {
                width: 100%;
                border: solid 0.3px $gray-light-color;
                border-radius: 5px;
                border-collapse: collapse;

                tr {

                    td,
                    th {
                        border-top: solid 0.3px $gray-light-color;
                        border-bottom: solid 0.3px $gray-light-color;
                        padding: 15px 10px;
                        text-align: left;

                        .table-header {
                            display: flex;
                            justify-content: space-between;

                            span {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }

    .payment-mode {
        .payment-title {
            font-size: 16px;
            color: $title-color;
            margin: 0;
        }

        .payment-options-wrap {
            padding: 24px 0;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }
}

.payment-form {
    font-size: 13px;
    padding: 20px;
    border: solid 0.5px $gray-light-color;
    border-radius: 7px;

    .payment-info-form {
        h2 {
            margin: 0;
            padding-bottom: 20px;
            font-size: 16px;
            font-weight: 600;
        }

        .single-row {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            .input-black {
                flex: 1 1 48%;
            }
        }
    }
}

.success-link {
    cursor: pointer;
    color: $success-button-background-color;
}

.bt-dot {
    border-top: dotted 0.6px $gray-light-color;
}

@media only screen and (max-width: 1543px) {
    .device-change-summary {
        width: 55vw;
    }
}

@media only screen and (max-width: 550px) {
    .display-information {
        width: 75vw;
        min-width: 0px;
    }
}