.pos-template-editor-table {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;

    /* ----------global styles-------*/
    .edit-icon {
        cursor: pointer;
    }

    .menu-subtitle-input {
        font-size: 15px !important;
        padding: 10px;
        border: none !important;
    }

    .menu-subtitle-input:focus {
        background-color: none !important;
        border-color: $template-blue !important;
        outline: 0 !important;
        box-shadow: none !important;
    }

    .top-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        padding-left: 1rem;
        padding-right: 1rem;
        flex-wrap: nowrap;
        border-bottom: 1px solid $template-border-color;
        padding-top: 20px;

        .header-title-wrapper {
            display: flex;
            align-items: center;
            gap: 30px;

            .save-file {
                display: flex;

                .save-file-form {
                    display: flex;
                    flex-direction: column;

                    .save-title-input {
                        max-width: 150px;
                        font-size: 15px !important;
                        border: none !important;
                    }

                    .save-title-input:focus {
                        background-color: none !important;
                        border-bottom: 1px solid $box-shadow-black !important;
                        outline: 0 !important;
                        box-shadow: none !important;
                    }

                    .text-danger {
                        color: $danger-text;
                        font-size: 12px;
                        font-weight: 500;
                        margin: 5px 0 0;
                    }
                }

                .edit-icon {
                    cursor: pointer;
                }
            }
        }
    }

    .right-side {
        flex: 0 0 auto; //col-md
        width: 66.66666667%;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: end;

            .header-button {
                display: flex;
                gap: 10px;
                flex-direction: column;
            }
        }

        .error-text {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
        }
    }

    .menu-body {
        display: flex;
        padding-left: 1rem;

        .side-menu {
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 15%;
            height: 100vh;
            text-align: center;
            padding: 1.5rem 0;
            padding-right: 10px;
            border-right: 1px solid $template-border-color;

            .side-menu-title {
                display: flex;
                align-items: center;
                gap: 15px;
                margin: 2%;
                letter-spacing: 1px;
                cursor: pointer;

                h5 {
                    font-weight: 500;
                    margin: 0;
                    color: $template-sidemenu-title;
                }

                &.active {
                    h5 {
                        color: $active-font-color;
                    }
                }
            }

            .input-black {
                gap: 0;

                .input-box {
                    border: none !important;
                    outline: none;
                    border-bottom: 1px solid $box-shadow-black !important;
                    font-size: 15px !important;
                    margin-right: 10px !important;
                    background: transparent;
                    border-radius: 0px !important;
                }
            }
        }

        .category-table {
            width: 100%;

            .pricing-text {
                font-weight: 500;
            }

            .template-main-title {
                display: flex;
                padding: 25px 0px 15px 25px;
                flex-direction: column;
                gap: 10px;

                .title-form {
                    display: flex;

                    .title-input {
                        max-width: 150px;
                        font-size: 15px !important;
                        border: none !important;

                        &:focus {
                            background-color: none !important;
                            border-bottom: 1px solid $box-shadow-black !important;
                            outline: 0 !important;
                            box-shadow: none !important;
                        }
                    }
                }

                .text-danger {
                    font-size: 14px;
                    font-weight: 400;
                }

                .edit-icon {
                    cursor: pointer;
                }
            }
            .tabs-container::-webkit-scrollbar {
                display: none;
            }
            .tabs-container {
                overflow: scroll;
                display: grid;
                grid-template-columns: max-content auto 1fr;
                align-items: center;
                padding-left: 20px;
                gap: 20px;
                width: 90%;
                .add-category {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .category-input {
                        border: none !important;
                        background-color: transparent !important;
                        border-bottom: 1px solid !important;
                        width: 200px;

                        &:focus {
                            background-color: none !important;
                            outline: 0 !important;
                            box-shadow: none !important;
                        }
                    }

                    .text-danger {
                        color: $danger-text;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .add-btn {
                    width: max-content !important;
                    border: none !important;
                    font-size: 14px !important;
                    color: #30A84B !important;
                    padding: 6px 0px;
                    cursor: pointer;

                    &:hover {
                        background-color: inherit !important;
                    }

                    &:active {
                        transform: none !important;
                        box-shadow: none !important;
                    }
                }
            }

            .categories {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;
                margin: 15px 0;
                padding: 0 18px;
                width: 90%;
                .inner-category {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;

                    span {
                        font-size: 14px;
                        color: $template-category-text;
                    }
                }
            }

            .product-image {
                width: 42px;
                height: 42px;
                border-radius: 3px;
            }

            .tableCells {
                width: max-content;

                .tableCell-form {
                    display: flex;
                    flex-direction: column;

                    .input-box {
                        font-weight: 400;
                        font-size: 0.9rem;
                        letter-spacing: 0.00938em;
                        // width: max-content;
                        resize: none;
                        height: auto;
                        outline: none;
                        border: none;
                    }

                    .text-danger {
                        color: $danger-text;
                        font-size: 12px;
                        font-weight: 500;
                        margin: 5px 0 0;
                    }
                }
            }

            .addItem-btn {
                font-size: 14px;
                color: $template-add-green;
                padding: 6px 0px;
                cursor: pointer;
            }

            .action-column {


                .action-buttons {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;
                }
            }

        }
    }
}
