.template-style-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: "column";

  .style-container {
    display: flex;
    height: 95vh;

    .style-box {
      display: flex;
      flex-direction: column;
      width: 70vw;

      .top-header {
        margin-left: 20px;
        font-size: 20px;
      }

      .content-box {
        display: flex;
      }
    }

  }

}