.dashboard-card-count {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;

  .each-count {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 10px;
    background-color: #ffffff;

    .count-top {
      padding: 15px 15px 15px 20px;
      border-bottom: 1px solid #CACACA;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .count-header {

        h4 {
          margin: 0;
          padding: 0;
          color: #4B4B4B;
          text-transform: capitalize;
          font-weight: 100;
          font-size: 13px;
        }

        h1 {
          padding-top: 10px;
          font-size: 24px;
          font-weight: 300 !important;
        }
      }

      .count-img {
        width: 45px;
        height: auto;
        object-fit: contain;
      }
    }

    .count-bottom {
      padding: 5px 25px 5px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        font-size: 12px;
        font-weight: 500 !important;
      }

      button {
        display: flex;
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width:1150px) and (min-width:450px) {
  .dashboard-card-count {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media only screen and (max-width:450px) {
  .dashboard-card-count{
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
