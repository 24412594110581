.billing {
  padding: 20px 20px 30px;
  position: relative;
  height: 100%;


  .loader-wrap {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subscription-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 0.75px dashed $border-color;

    .title-wrap {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        gap: 10px;
        align-items: center;

        .title {
          font-size: 16px;
          color: $title-color;
        }

        .status {
          margin: 0;
          font-size: 13px;
          padding: 6px;
          border-radius: 3px;

          &.active {
            background-color: $success-table-header-background-color;
            color: $success-text;
          }

          &.suspended {
            background-color: $suspend-danger-background-color;
            color: $danger-text-secondary;
          }
        }
      }

      .form-button {
        .btn .default-button {
          padding: 6px 15px;
          border: 0.75px solid $border-color;
          border-radius: 6px;
        }
      }

    }

    .detail-row {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;

      .detail-label {
        width: 15%;
        color: $title-color-2;
        font-size: 14px;
      }

      .detail-value {
        margin: 0;
        font-size: 14px;
        color: $title-color;
      }
    }
  }

  .billing-form {
    display: flex;
    flex-direction: column;
    border-bottom: 0.75px dashed $border-color;

    .billing-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 14%;
      padding: 20px 0;

      h2 {
        font-size: 16px;
        color: $black-color;
        font-weight: 600;
      }

      span {
        transition: all 0.3s ease-in-out;
      }
    }

    .billing-details {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: all 0.4s ease-in-out;

      .detail-row {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;

        .detail-label {
          width: 15%;
          color: $title-color;
          font-size: 14px;
        }

        .detail-value {
          margin: 0;
          color: $title-color;
          font-size: 14px;
        }
      }

      .form-button-group {
        padding: 20px 0 0;
        border-top: 0.75px dashed $border-color;
      }
    }

  }
}