.upload-media {
  width: 800px;
  max-height: 80vh;
  overflow-y: scroll;
  padding: 0 20px 20px 2px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .url-image-preview-container {
      p {
        margin: 5px 0;
      }

      .youtube-video {
        width: inherit;
        height: 255px;
      }

      .url-image {
        background-color: $button-background;
        height: 225px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        iframe {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }

    .thumbnail-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      overflow: scroll;

      .thumbnail-image {
        width: 100%;
        height: 80px;
        object-fit: cover;
        border: 2px solid $white-color;
        border-radius: 10px;
        cursor: pointer;
      }

      .selectedThumbnail {
        border: 2px solid $black-color;
      }
    }

    .skeleton-loader {
      height: 80px;
    }

    .form-fields {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .input-black {
        gap: 10px;

        .input-box {
          line-height: 1;
          padding: 14px 9px;
        }
      }

      .form-fields-row {
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-between;

        .input-black {
          gap: 7px;

          .input-box {
            padding: 10px 9px;
          }
        }
      }
    }

    .advance-settings-wrap {
      // border-top: 0.5px solid $border-color;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .advance-header {
        display: flex;
        align-items: center;
        gap: 14px;
        cursor: pointer;

        h2 {
          font-size: 16px;
          color: $black-color;
          font-weight: 600;
        }

        span {
          display: flex;
          transition: all 0.1s ease-in-out;
        }
      }

      .settings-input {
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow: hidden;
        transition: all 0.4s ease-in-out;

        .single-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 6%;
        }
      }
    }

    .content-button-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .upload-count {
        display: flex;
        align-items: center;
        gap: 10px;
        pointer-events: none;
    
        span {
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: $paragraph-grey-color;
        }
      }

      .button-wrap {
        display: flex;
        gap: 16px;
      }
    }
  }
}

// @media (max-height: 800px) {
//     .upload-media {
//         height: 80vh;
//     }
// }
