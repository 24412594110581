.custom-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 2px solid $border-color;
    background-color: $white-color;

    .layout-left-wrap {
        display: flex;
        width: 30%;
        align-items: center;
        gap: 10%;
        cursor: pointer;
    }

    .back-button {
        font-size: 24px;
        background: none;
        border: none;
        cursor: pointer;
    }

    .dimension-inputs {
        display: flex;
        align-items: center;
        gap: 10%;

        label {
            display: flex;
            align-items: center;
            font-weight: bold;
            color: $black-color;

            .dimension-input {
                width: 60px;
                padding: 5px;
                margin: 0 5px;
                border: 1px solid $gray-light-color;
                border-radius: 4px;
                text-align: center;
                font-size: 14px;
            }
        }

        .dimension-multiplier {
            font-size: 16px;
            color: $black-color;
        }
    }

    .name-container {
        border: 1px solid $border-color;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        .name-input {
            width: 80%;
            border: none;
            padding: 10px;
            outline: none;
        }
    }

    .create-layout-button {
        padding: 8px 16px;
        background-color: $gray-color;
        font-size: 14px;
        color: $white-color;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .create-layout-button-active {
        padding: 8px 16px;
        background-color: $success-button-background-color;
        font-size: 14px;
        color: $white-color;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
}

.hide-while-converting {
    display: none !important;
}