.toggle-btn {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px 22px;
  cursor: pointer;

  .list-view-btn {
    padding: 6px;
    background-color: $button-background;
  }

}