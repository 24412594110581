@font-face {
  font-family: 'Knewave';
  src: url(../../../assets/fonts/knewave/Knewave-Regular.ttf)format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Luckiest Guy';
  src: url(../../../assets/fonts/luckiestGuy/LuckiestGuy-Regular.ttf)format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'McLaren';
  src: url(../../../assets/fonts/mcLaren/McLaren-Regular.ttf)format('truetype');
  font-weight: 400;
  font-style: normal;
}

.template-container-3 {
  background-color: $pure-black;
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  padding: 30px;


  .inner-container {
    border: 1px solid $white-color;
    padding: 40px 30px 30px 40px;

    .dotted-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 10px dotted $white-color;
      padding: 40px;

      .grid-item {
        width: 100%;
        margin: 0 auto;
      }

      .content-container {
        display: flex;
        justify-content: space-between;

        .left-ice,
        .right-ice {
          width: 20%;
          // height: 250px;
        }

        .top {
          width: 35%;
          text-align: center;
          position: relative;

          .ice-text-wrapper {
            position: absolute;
            left: 50%;
            width: 100%;
            height: 40%;
            transform: translateX(-50%);
          }
        }
      }

      .float-end {
        display: flex;
        justify-content: flex-end;

        .arrow-icon {
          width: 10%;
          // height: 100px;
        }
      }

      .bottom-ice-container {
        display: flex;
        gap: 40px;
        margin: 1rem auto 0;
        width: 100%;

        .bottom-ice-wrapper {
          width: 18%;

          .bottom-ice {
            width: 100%;
            height: 100%;
            margin-top: 1rem;
          }
        }

        .menu-lists {
          display: flex;
          flex-direction: column;
          width: 40%;

          .menu-list-header {
            display: flex;
            text-align: center;
          }

          ul.list-group {
            li {
              display: table;
              padding: 0.5rem 0rem;

              span {
                display: table-cell;

                &:first-child {
                  /* TITLE */
                  position: relative;
                  overflow: hidden;
                  /* Don't go underneath the price */

                  &:after {
                    /* DASHES */
                    content: "";
                    position: absolute;
                    bottom: 0.5em;
                    /* Set as you want */
                    margin-left: 0.3em;
                    /* Keep same for the next span's left padding */
                    width: 100%;
                    border-bottom: 2.5px dotted $white-color;
                  }
                }

                +span {
                  /* PRICE */
                  text-align: right;
                  width: 1%;
                  /* Trick it */
                  vertical-align: bottom;
                  /* Keep Price text bottom-aligned */
                  padding-left: 0.3em;
                  // white-space: nowrap; /* Uncomment if needed */
                }
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (min-width: 1550px) {
  .template-container-3 .inner-container .dotted-container .grid-item {
    width: 70%;
  }

  .template-container-3 .inner-container .dotted-container .bottom-ice-container {
    width: 75%;
  }
}

@media screen and (max-width: 1280px) {
  .template-container-3 .inner-container .dotted-container .grid-item {
    width: 100%;
  }

  .template-container-3 .inner-container .dotted-container .bottom-ice-container {
    width:100%;
  }
}