.ai-reports {
    padding: 20px 15px;

    .top-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        .title {
            font-size: 20px;
        }

        .filters {
            display: flex;
            align-items: center;
            gap: 10px;

            .filter-label {
                font-size: 13px;
                color: $title-color-2;
                margin: 0;
                white-space: nowrap; // Updated
            }

            .select-boxes {
                display: grid;
                grid-template-columns: 130px 190px 140px;
                gap: 25px;

                .select-box-container {
                    background-color: #fff;
                }

                .input-black {
                    .label {
                        display: none;
                    }
                }
            }
        }
    }

    .bar-chart {
        box-shadow: 0px 1px 8px 1px #0000000D;

        .bar-chart-top-content {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            padding: 10px 48px 0px 20px;
            border-radius: 6px 6px 0 0;

            .title {
                color: $title-color;
                font-weight: 600;
            }

            .bar-chart-category {
                width: 188px;
            }
        }
    }
}

//Report bar chart
.report-user-total-count {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 0 0 6px 6px;

    .bar-chart-container {
        .recharts-cartesian-axis-tick-line {
            display: none;
        }

        .recharts-text {
            .recharts-cartesian-axis-tick-value {
                font-size: 13px;
                color: black;
            }
        }
    }
}

.custom-legend {
    padding-right: 197px;
    margin-top: -446px;
    text-align: end;

    .legend-item {
        display: inline-block;
        margin-right: 10px;

        .legend-color {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 4px;
            border-radius: 20px;
        }
    }
}

.custom-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
    }
}