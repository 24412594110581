.widget-view {
    overflow-y: scroll;
    width: 100%;
    padding: 12px 22px;

    .widget-top {
        height: 55px;
        display: flex;
        align-items: center;
        padding: 0px 0px 10px;

        .widget-title {
            font-weight: 600;
            font-size: 16px;
            color: $default-button-hover-background-color;
        }
    }

    .widget-wrapper {
        // margin: 5px;
        height: 100%;
        background-color: $white-color;
        border-radius: 8px;
        box-shadow: 0px 0px 9px $box-shadow-color;

        .widget-content {
            display: flex;
            flex-direction: column;

            .widget_tabs-container {
                display: flex;
                gap: 20px;
                padding-left: 5px;
                padding-bottom: 0px;
                margin-bottom: 0px;

                .tabs-content {
                    color: $gray-color;
                    margin: 0px;
                    margin-top: 10px;
                    padding: 0px;
                    cursor: pointer;
                }

                .tabs-content-chosen {
                    color: $success-text;
                    margin: 10px 0px 0px 0px;
                    padding: 0px;
                }

                .widget-underline-bar {
                    width: inherit;
                    margin-top: 5px;
                    padding-top: 0px;
                }

                .widget-underline-bar-chosen {
                    width: inherit;
                    border: 1px solid $success-text;
                    margin-top: 5px;
                    padding-top: 0px;
                }
            }

            .widget-card {
                display: flex;
                gap: 50px;
                flex-wrap: wrap;
                margin: 20px;

                .widget-card-item {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    min-width: 207px;
                    width: calc(100%/6);
                    flex-grow: 0;

                    .widget-image {
                        height: 136px;
                        // width: 207px;
                        border-radius: 5px;
                        box-shadow: 0px 1.08px 8.67px 1.08px rgba(0, 0, 0, 0.0784313725);
                        background: #fff;
                    }

                    .wg-name {
                        font-size: 13px;
                        font-weight: 400;
                        color: $black-color;
                        width: 85%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .timeformat {
                        font-size: 12px;
                        font-weight: 400;
                        margin: -12px 0px 0px 0px;
                        color: $store-size-color;
                    }

                    .widget-card-container {
                        width: 170px;
                        height: 170px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 1px 1px 5px 1px $box-shadow-widget;
                        position: relative;
                        overflow: hidden;
                        background-size: cover;
                        transition: background-image 0.3s ease;

                        .widget-image {
                            height: 45px;
                            width: 45px;
                            object-fit: cover;
                            transition: opacity 0.3s ease;
                        }

                        .hover-image {
                            display: none;
                            height: 100%;
                            width: 100%;
                        }

                        &:hover {
                            .hover-image {
                                display: block;
                            }

                            .widget-image {
                                display: none;
                            }

                            background-image: none;
                        }
                    }

                    .card {
                        box-shadow: 0px 1.08px 8.67px 1.08px rgba(0, 0, 0, 0.0784313725);
                        padding: 6px;
                        height: 136px;

                        .shimmerBG {
                            animation-duration: 2.2s;
                            animation-fill-mode: forwards;
                            animation-iteration-count: infinite;
                            animation-name: shimmer;
                            height: 100%;
                            animation-timing-function: linear;
                            background: #eeeeee;
                            background: linear-gradient(to right, #eeeeee 8%, #fffdfd 18%, #eeeeee 33%);
                            background-size: 1200px 100%;
                            border-radius: 6px;
                        }

                        @-webkit-keyframes shimmer {
                            0% {
                                background-position: -100% 0;
                            }
    
                            100% {
                                background-position: 100% 0;
                            }
                        }

                        @keyframes shimmer {
                            0% {
                                background-position: -1200px 0;
                            }
    
                            100% {
                                background-position: 1200px 0;
                            }
                        }
                    }
                }
            }
        }

        .widget-scroll {
            height: 75vh;
            margin: 10px;
            overflow-y: scroll;
        }

    }
}

.widget-preview .widget-content .content-preview {
    height: 100vh;
}
.widget-preview .widget-content {
    height: 100vh;
}