.schedule-saved-layout-wrap {
  .saved-layout-wrap {
    overflow: scroll;
    min-width: 50vw;

    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .single-layout-wrap {
      width: 220px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;

      .layout-image-wrap {
        width: inherit;
        height: 140px;
        border: var(--single-layout-border);
        border-radius: 6px;
        box-shadow: 0px 1px 8px 1px #00000014;
        // transition: all 0.1s ease-in-out;

        .card {
          box-shadow: 0px 1.08px 8.67px 1.08px rgba(0, 0, 0, 0.0784313725);
          padding: 6px;
          height: 136px;

          .shimmerBG {
            animation-duration: 2.2s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: shimmer;
            height: 100%;
            animation-timing-function: linear;
            background: #eeeeee;
            background: linear-gradient(to right, #eeeeee 8%, #fffdfd 18%, #eeeeee 33%);
            background-size: 1200px 100%;
            border-radius: 6px;
          }

          @-webkit-keyframes shimmer {
            0% {
              background-position: -100% 0;
            }

            100% {
              background-position: 100% 0;
            }
          }

          @keyframes shimmer {
            0% {
              background-position: -1200px 0;
            }

            100% {
              background-position: 1200px 0;
            }
          }
        }

        .layout-image {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .layout-details {
        width: inherit;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .layout-header {
          width: inherit;
          display: grid;
          grid-template-columns: max-content 1fr;
          align-items: center;
          gap: 10px;

          .layout-header-avatar {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: $paragraph-blue-color;

            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: $white-color;
              font-weight: 600;
              font-size: 12px;
            }
          }

          h1 {
            margin: 0;
            font-size: 13px;
            font-weight: 600;
            color: $title-color;

            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        p {
          margin: 0;
          font-size: 12px;
          font-weight: 500;
          color: $title-color-2;
        }
      }
    }
  }

  .saved-layout-button-wrap {
    padding: 15px 0 0;
    display: flex;
    justify-content: flex-end;
    gap: 25px;

    .btn {
      width: max-content;
    }
  }
}