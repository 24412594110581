.location-wrap {
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 10px;
  background-color: $white-color;

  .lat-lng-text {
    padding: 10px 0px 5px 20px;
  }

  .location-header {
    padding: 10px 50px 10px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }

    .location-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      .place {
        display: flex;
        gap: 5px;

        .location-image {
          width: 20px;
          height: auto;
        }

        .input-black {
          width: 150px;
          gap: 0px;

          .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
            border: none;
          }

          .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
            display: none;
          }
        }
      }

      .store {
        p {
          font-size: 12px;
          color: $gray-color;
        }
      }
    }
  }

  .store-information {
    display: flex;
    flex-direction: column;
    width: 298px;

    .store-image {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $title-color-2;
      border-radius: 10px;

      width: 300px;
      height: 180px;
      background-color: $button-background;

      .store-image-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: inherit;
        height: inherit;

        .next-btn,
        .prev-btn {
          opacity: 0;
          position: absolute;
          z-index: 9;
          cursor: pointer;
          transition: 0.2s all ease-in-out;
        }

        .next-btn-inactive,
        .prev-btn-inactive {
          display: none;
        }

        .prev-btn {
          left: 8px;
          top: 79px;
        }

        .next-btn {
          right: 8px;
          top: 79px;
        }

        .image-container {
          width: 302px;
          height: 179px;

          img {
            border-radius: 10px 10px 0 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &:hover {

          .next-btn,
          .prev-btn {
            opacity: 1;
          }
        }
      }

      .store-default-image {
        height: inherit;
        width: inherit;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {}

        p {
          margin: 5px 0;
        }
      }

    }

    .store-content {
      background-color: $white-color;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 0 20px 0;

      .store-details {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 15px 0 15px;

        .store-info-header {
          display: flex;
          gap: 10px;

          .header-name {
            font-size: 18px;
            color: $black-color;
          }

          .device-count {
            background-color: $success-table-header-background-color;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $success-text;
            padding: 0px 4px 0px 4px;
          }
        }

        .store-location-details {
          display: flex;
          gap: 5px;
        }
      }

      .store-device-details {
        padding: 0 15px 0 15px;
        justify-content: space-between;
        border-top-style: dashed;
        border-top-color: $card-border-color;
        border-top-width: 1px;
        display: flex;

        .device-details {
          padding-top: 15px;
          display: flex;
          gap: 5px;
          flex-direction: column;

          .count {
            color: $black-color;
            font-size: 16px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .leaflet-top {
    z-index: 998;
  }

  .leaflet-popup-content-wrapper {
    // width: 300px;

    .leaflet-popup-content {
      width: 100% !important;
      margin: 0px !important;
    }
  }

  .slick-dots {
    bottom: 7px;
  }

  .slick-next {
    display: none !important;
    right: 13px;
  }

  .slick-prev {
    display: none !important;
    z-index: 999;
    left: 13px;
    right: 6px;
  }

  .leaflet-container a.leaflet-popup-close-button {
    border-radius: 50px;
    background-color: $button-gray-background-color;
    color: $white-color;
    top: 7px;
    right: 10px;
    width: 23px;
    height: 23px;
  }

  .slick-dots li button:before {
    color: $card-border-color;
  }

  .slick-dots li.slick-active button:before {
    color: $white-color;
  }

  .slick-prev:before,
  .slick-next:before {
    background-color: $button-gray-background-color;
    color: $white-color;
  }

  .leaflet-bottom {
    z-index: 400;
  }

  .leaflet-top {
    z-index: 400;
  }
}

@media screen and (max-width: 450px) {
  .location-wrap {


    .lat-lng-text {
      padding: 10px 0px 5px 20px;
    }

    .location-header {
      padding: 10px 23px 10px 12px;

      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }

      .location-right {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;

        .place {
          display: flex;
          gap: 5px;

          .location-image {
            width: 20px;
            height: auto;
          }

          .input-black {
            width: 150px;
            gap: 0px;

            .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
              border: none;
            }

            .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
              display: none;
            }
          }
        }

        .store {
          p {
            font-size: 12px;
            color: $gray-color;
          }
        }
      }
    }

    .pop-up {
      height: 300px;

      h1 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 14px;
      }

      .pop-up-click {
        font-size: 12px;
        text-decoration: underline;
        padding: 10px 0 0 0;
        color: blue;
        cursor: pointer;
      }
    }

    .leaflet-top {
      z-index: 998;
    }
  }
}