.user-profile {
    .user-form-wrap {
        display: grid;
        grid-template-columns: 30% auto;
        // grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto;
        padding: 20px;
        gap: 1rem;

        .profile-upload {
            grid-area: 1 / 1 / 3 / 2;
            height: max-content;
            padding: 70px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            border-radius: 8px;
            position: relative;

            .upload-click {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                cursor: pointer;

                img {
                    width: 125px;
                    height: 125px;
                    object-fit: cover;
                    border-radius: 50%;
                    -webkit-transition: .3s ease-in-out;
	                transition: .3s ease-in-out;
                }

                p {
                    margin: 0;
                    font-size: 16px;
                    color: $gray-color;
                    text-align: center;
                    padding: 10px 20px;
                    border: 1px solid #9EA3A9;
                    border-radius: 6px;

                    span {
                        color: #212B36;
                    }
                }
            }

            .close-icon-button {
                display: none;
                position: absolute;
                cursor: pointer;
                width: 125px;
                height: 125px;
                top: 70px;
                border-radius: 50%;
                background-color: #00000080;
                justify-content: center;
                align-items: center;
            }
            &:hover .close-icon-button {
                display: flex;
            }
        }
    }

    .user-form, .company-form {
        grid-area: 1 / 2 / 2 / 3;
        padding: 30px 25px;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: 8px;

        .title {
            font-size: 16px;
            color: $title-color;
            margin-bottom: 10px;
        }

        .single-row {
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;
        }

        .input-black {
            .select-input-box {
                .list-chips {
                    flex: 4 1;
                }
            }
        }
    }

    .company-form {
        grid-area: 2 / 2 / 3 / 3;
    }
}

.deleteAccount {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 35vw;

    .request-content {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 20px;
    }

    .description {
        line-height: 25.2px;
    }
}