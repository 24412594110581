.usage-report {
    .title {
        margin: 15px 0;
        font-size: 14px;
        font-weight: 700;
    }

    .content-body {
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 0px 9px #f0f0f0;
        height: 277px;

        .no-data-found {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .no-data-message {
                font-size: 16px;
                font-weight: 600;
            }

            .no-data-description {
                font-size: 14px;
                font-weight: 400;
                color: $grey-2;
            }
        }
    }

    .content-body.with-data {
        height: 332px;
        padding: 20px 30px 25px;

        .usage-report-container {
            width: 100%;
            height: 100%;

            .report-title-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .report-title {
                    font-size: 14px;
                }

                .select-boxes {
                    display: flex;
                    gap: 20px;
                    width: 30%;
                }
            }

            .chart-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                height: 100%;

                .pie-chart-container {
                    display: flex;
                    align-items: center;

                    .pieChart-wrap {
                        width: 180px;
                        height: 180px;
                    }

                    .pie-chart-legend {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        .legend-wrap {
                            display: flex;
                            justify-content: space-between;
                            gap: 20px;

                            .each-legend {
                                display: flex;
                                gap: 10px;

                                .marker {
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 50%;
                                }

                                .label {
                                    font-size: 14px;
                                    color: $paragraph-grey-color;
                                }
                            }

                            .hours {
                                margin: 0;
                            }
                        }

                        .total-label {
                            color: $paragraph-grey-color;
                        }
                    }
                }

                .bar-chart-container {
                    width: 100%;

                    .barChart-wrap {
                        width: 100%;
                        height: 180px;

                        .x-axis,
                        .y-axis {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

.form-button-right {
    display: flex;
    justify-content: end;
    gap: 10px;

    .btn {
        width: 100px;
    }
}