.app {
  margin: 0;
  padding: 0;
}

.layout {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .header-session {
    width: 100%;
    height: 8vh;

    .logo-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
    }
  }

  .main-session {
    height: 92vh;
    background-color: $white-color;
    display: grid;
    grid-template-columns: max-content 1fr 15px;

    .content-session {
      position: relative;
      height: 92vh;
      width: inherit;

      overflow: hidden;

      .outlet-container {
        height: 89vh;
        width: inherit;

        background-color: $button-background;
        border-radius: 5px;

        transition: all 0.4s ease-in-out;
        overflow-x: hidden;

        .content {
          border-radius: 8px;
          padding: 12px 22px;

          .main-content {
            display: flex;
            flex-direction: column;

            .content-area {
              border-radius: 8px;
              background-color: $white-color;
              box-shadow: 0px 0px 9px #f0f0f0;
            }
          }
        }
      }
    }
  }
}

.text-danger {
  font-size: 14px;
  font-weight: 600;
  color: $danger-text !important;
}

.text-success {
  color: $success-text;
}

.message {
  margin-left: 20px;
}

.success-tag {
  background: #F7FAFC;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #198437;
  font-size: 13px;
  width: 100px;
  align-items: center;
  padding: 10px;
}

.warning-tag {
  background: #fefef7;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #847f19;
  font-size: 13px;
  width: 100px;
  align-items: center;
  padding: 10px;
}

.danger-tag {
  background: #ffd7d973;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #E8212e;
  font-size: 13px;
  width: 100px;
  align-items: center;
  padding: 10px;
}

.manus {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    width: 159px;
    margin-top: -20px;
    // left: 1278px !important;
    border: #CACACA solid 1px;
  }
}

.card {
  background: #fff;
  border-radius: 8px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border: #CACACA solid 1px;
  height: auto;
  max-height: 250px !important;
}


#basic-menuundefined {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    width: 159px;
    // top: 91px !important;
    left: 1339px !important;
    border: #CACACA solid 1px;
  }
}

.muiltr-i29csa {
  margin: 0 !important;
}

.text-center {
  display: flex;
  justify-content: center !important;
}

.muiltr-u4tvz2-MuiFormLabel-root,
.muiltr-ahj2mt-MuiTypography-root {
  font-family: Poppins !important;
}

.icon-text {
  display: flex;
  gap: 10px;
  align-items: center;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .layout {
    .main-session {
      display: grid;
      grid-template-columns: max-content 1fr 2px;
    }
  }
}

// @media screen and (max-width: 450px) {
//   .layout {
//     // overflow-y: scroll;
//   }

//   .side-bar-session {
//     // display: none;
//   }

//   .content-session {
//     background-color: white;

//     .content {
//       background-color: none;
//     }
//   }
// }

// @media screen and (max-width: 1250px) and (min-width: 450px) {
//   .layout {
//     .side-bar-session {
//       width: 67px;
//     }

//     .content-session {
//       width: 94%;
//     }
//   }
// }

// @media screen and (max-width: 450px) {
//   .layout {

//     .side-bar-session {
//       width: 0;

//       .logo-image {}
//     }

//     .content-session {
//       background-color: white;
//       width: 100%;
//       overflow-y: scroll;

//       .dashbord-content {

//         .count-cards {
//           background-color: white;

//         }

//         .footer-tab {}

//         .dashbord-wrap {
//           height: unset;
//           background-color: #fff;

//           .schedules-devices {
//             display: flex;
//             flex-direction: column;
//             gap: 30px;
//           }

//           .display-content {
//             display: flex;
//             flex-direction: column;
//             gap: 30px;
//           }
//         }

//       }
//     }
//   }
// }