@font-face {
    font-family: 'Inter';
    src: url(../../../assets/fonts/inter/Inter-Bold.ttf)format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: url(../../../assets/fonts/urbanist/Urbanist-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

.template-container-13 {
    width: 100%;
    height: 100vh;
}