.template-container-5 {
    background-color: $pure-black;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;

    .inner-container {
        width: 100%;

        .bar-display {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            // padding: 2%;
            position: relative;
            z-index: 2;

            .bar-display-container {
                display: flex;
                gap: 10px;
                justify-content: center;
                width: 100%;
                position: relative;

                .left-side {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    width: 70%;

                    .item-name {
                        width: 30%;
                        margin-bottom: 50px;
                    }

                    .item-images {
                        display: flex;
                        align-items: flex-end;
                        margin-left: 15%;
                        width: 50%;

                        // margin-bottom: -50px;
                        .item-image-wrapper {
                            margin-top: 41px;
                        }

                        .item-image-1 {
                            width: 100%;
                            height: 100%;
                        }

                        .item-image-2 {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                //starts with right side
                .right-side {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: right;
                    margin-right: 25px;
                    margin-bottom: 90px;
                    width: 30%;
                }

                .bottom-image-wrapper {
                    position: absolute;
                    bottom: -12px;
                    left: -22px;
                    z-index: -1;

                    .bottom-image {
                        width: 100%;
                        height: 30%;
                    }
                }
            }
        }
    }

}