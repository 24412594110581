.floor-map-form {
    width: 30vw;

    .floor-map-fields {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 70vh;
        overflow-y: scroll;
        padding: 10px 0px;

        .selected-image-session {
            border: solid 0.5px $gray-light-color;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 10px;

            .thumb-image {
                width: 40px;
            }

            .file-name {
                flex: 1;
                font-size: 14px;
                display: flex;
                gap: 10px;
                flex-direction: column;

                .subtext {
                    font-size: 12px;
                    display: flex;
                    gap: 10px;
                    span {
                        text-transform: uppercase;
                        color: $success-button-background-color;
                    }
                }
            }

            .file-close {
                width: 20px;
                cursor: pointer;
            }
        }
    }
}