.pop-status {
    display: flex;
    align-items: center;
    gap: 5px;
}

.pop-status-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .pop-loader {
        cursor: pointer;

        .refresh-loading {
            display: inline-block;
            animation: spin 1s linear infinite;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .refresh-icon {
            height: 100%;
            display: flex;
            align-items: center;
            transition: transform 0.3s ease;
        }
    }
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}