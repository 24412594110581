.dashboard-wrap {
  height: inherit;
  border-radius: 8px;
  background-color: $button-background;
  overflow-y: scroll;


  .count-cards {
    padding: 12px 12px 35px;
    background-color: $button-background;
    position: sticky;
    z-index: 499;
    top: 0;


    h1 {
      line-height: 44px;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .footer-tab {
    padding: 12px 12px 0px;
    top: 0;
  }

  .dashboard-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 0 12px 61px 12px;
    // height: 64vh;
    // overflow: scroll;

    .schedules-devices {
      display: grid;
      grid-template-columns: 60% auto;
      gap: 30px;
    }

    .display-content {
      display: flex;
      gap: 20px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    color: $success-text;
  }
}

@media only screen and (max-width:1150px) and (min-width:450px) {
  .dashboard-wrap {
    .dashboard-content {
      .schedules-devices {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      .display-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard-wrap {
    .count-cards {
      position: static;
    h1 {
      font-size: 18px;
    }
    }

    .dashboard-content {
      .schedules-devices {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
      .display-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}