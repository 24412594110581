.new-content {
    width: 450px;

    .content-buttons {
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .buttons-list {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;

            .btn {
                button {
                    padding: 17px 15px;
                    color: $title-color;
                    border: 1px solid $card-border-color;
                    border-radius: 6px;
                    transition: all 0.2s;

                    &:hover {
                        border: 1px solid $title-color;
                        background-color: $button-background;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 676px) {
    .new-content {
        width: 100%;
    }
}