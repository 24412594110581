.dashboard-display-status {
  width: 50%;
  height: 100%;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .Status-header {
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .pieChart-wrap {
    width: inherit;
    height: 280px;
  }

  .pieChart-legend {
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 15px 0 0;
    border-top: 0.75px dashed $card-border-color;

    .each-legend {
      display: flex;
      align-items: center;
      gap: 5px;

      .marker {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      p {
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width:1150px) and (min-width:450px) {
  .dashboard-display-status {
    width: 100%;

    .dashboard-content {
      .schedules-devices {
        display: grid;
        grid-template-columns: none;
        gap: 30px;
      }
    }
  }
}

@media screen and (max-width:450px) {
  .dashboard-display-status {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background-color: $white-color;
    border-radius: 10px;
    border-radius: 10px;
    padding-bottom: 5%;

    .Status-header {
      display: flex;
      align-items: center;
      padding: 5px;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .pieChart-wrap {
      margin-left: 10px;
      display: flex;

      .pieChart-circle {
        padding-left: 0;
        width: 70%;
        height: 280px;
      }

      .legend {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 30%;
        gap: 10px;

        .each-legend {
          position: relative;
          display: flex;

          .marker {
            position: absolute;
            top: 4px;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }

          p {

            margin: 0;
            margin-left: 15px;
            font-size: 14px;
            cursor: pointer;

          }
        }
      }
    }
  }

  .pieChart-legend {
    .each-legend {
      font-size: 14px;

      p {
        font-size: 13px;
      }
    }
  }
}
