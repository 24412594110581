// Templates
$template-blue: #86b7fe;
$template-avatar-blue: #0277F4;
$template-setting: #dee2e6;
$template-black: #00000014;
$box-shadow-black: rgba(0, 0, 0, 0.1);
$box-shadow-lightBlack: rgba(60, 64, 67, 0.3);
$box-shadow-dark: rgba(0, 0, 0, .34901960784313724);
$box-shadow-green: rgba(49, 176, 148, 1);
$box-shadow-dark-black: rgb(0 0 0 / 60%);
$active-font-color: #212B36;
$template-border-color: #9EA3A9;
$template-add-green: #30A84B;
$template-category-text: #5C646D;
$template-sidemenu-title: #9b9b9b;
$pure-white: #FFFFFF;

// Template - 2
$template-2-yellow: #F9C92B;
$template-2-gray: #38383A;
$template-2-mustard: #E7A12F;

//Template - 3
$pure-black: #000000;

// Template - 4
$template-4-yellow: #F7B42F;

// Template - 6
$template-6-green: #6ca00f;
$template-6-black: #201c1b;
$template-6-orange: #e57837;

// Template - 7
$template-7-olive: #45531e;
$template-7-lightGreen: #62742a;
$template-7-white: #ededed;

// Template - 8
$template-8-green: #013437;
$template-8-white: #fdfeee;
$template-8-gray: #7e7e74;
$template-8-gradient: linear-gradient(to right,
                rgb(32, 31, 31),
                rgba(45, 44, 44, 0.5),
                rgba(0, 0, 0, 0));
// Templates - 9
$template-9-green: #64872B;
$template-9-white : #FEF2CC;

// Templates - 10

$template-10-green: #88ad4d;
$template-10-white: #f1e6d4;
$template-10-lightGreen: #75AE43;

//Template - 13
$template-13-background: #FEF5E2;