.schedule-add-content-options-wrap {
  width: 400px;

  .content-options-wrap {
    padding: 24px 0;

    display: flex;
    flex-direction: column;
    gap: 32px;

    .each-content-option {}
  }

  .rearrange-button-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    .btn {
      width: max-content;
    }
  }
}