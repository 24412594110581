.register-form-wrap {
    position: relative;

    height: 100vh;
    width: 100vw;
    background-color: #EEF2F6;

    display: flex;
    align-items: center;
    justify-content: center;

    .register-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border-radius: 8px;
        padding: 4% 3% 3%;

        .register-logo {
            height: auto;
            width: 175px;
            object-fit: contain;
        }

        .form-header {
            margin: 5% 0;

            h1 {
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                color: $success-text;
            }

            p {
                margin: 4% 0 0;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: $label-color;
                line-height: 25px;
            }
        }

        .errorMsg {
            margin: 7px 0 0;
            font-size: 14px;
            color: $danger-text;
            font-weight: 600;
        }
    }

    .language-wrap-absolute {
        position: absolute;
        top: 2%;
        right: 2%;

        display: flex;
        align-items: center;
    }

    .language-wrap-absolute-ar {
        position: absolute;
        top: 2%;
        left: 2%;

        display: flex;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .register-form-wrap {
        height: 100vh;

        .form-header {
            margin: 5% 0;

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 450px) {

    .register-form-wrap {
        .register-form {
            .register-logo {
                width: 150px;
            }

            .form-header {
                h1 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}
