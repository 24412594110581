.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 1.5px 4px 1.5px 5px !important;
}

.info-text {
    font-size: 14px;
    color: $blue-color;
}

.css-4jnixx-MuiStack-root,
.css-1jvos7y-MuiStack-root {
    padding-top: 0px !important;
    overflow: visible !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 11.5px 9px !important;
    font-size: 14px !important;
    // color: $gray-color !important;
}

.css-1jvos7y-MuiStack-root>.MuiTextField-root,
.css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: 0px !important;
}

.css-jj2ztu {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 15px !important;
}

// Dropdown border in AutoComplete
.MuiPopper-root.MuiAutocomplete-popper.css-1s5lphu-MuiPopper-root-MuiAutocomplete-popper {
    border: 1.5px solid $gray-color !important;
    border-radius: 3px !important;
}