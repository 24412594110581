.btn {
    width: 100%;

    .cancel-button {
        all: unset;
        color: red;
    }

    .success-button,
    .danger-button,
    .default-button,
    .dark-button,
    .success-button-outline,
    .info-button {
        // min-height: 45px;
        // min-width: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        border: 0px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        padding: 6px 8px;
        border-radius: 3px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        color: $white-color;
        // width: 100%;
        cursor: pointer;
    }

    button:disabled,
    button[disabled] {
        background-color: $gray-color;
        color: #ffffff;
        border: solid 1px $gray-color;

        &:hover {
            background-color: $gray-color;
            border-color: $gray-color;
        }
    }

    button:active {
        transform: scale(0.98);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .success-button {
        // min-width: 134px;
        padding: 6px 20px;
        background-color: $success-button-background-color;
        border: solid 1px $success-button-background-color;

        &:hover {
            background-color: $success-button-hover-background-color;
            border: solid 1px $success-button-hover-background-color;
        }
    }

    .success-button-outline {
        min-width: 134px;
        color: $success-button-background-color;
        background-color: $white-color;
        border: solid 1px $success-button-background-color;
    }

    .round-button {
        border-radius: 50px;
    }

    .info-button {
        background-color: $info-button-background-color;
        border: none;

        &:hover {
            background-color: $info-button-hover-background-color;
        }
    }

    .danger-button {
        background-color: $danger-button-background-color;
        border: solid 1px $danger-button-background-color;

        &:hover {
            background-color: $danger-button-hover-background-color;
            border: solid 1px $danger-button-hover-background-color;
        }
    }

    .default-button {
        padding: 6px 20px;
        background-color: $default-button-background-color;
        border: solid 1px $black-color;

        span {
            color: $title-color;
        }

        &:hover {
            // background-color: $default-button-hover-background-color;
            // border-color: $default-button-hover-background-color;
            // color: $white-color;
            background-color: #0000001e !important;
        }

        &:disabled {
            background-color: $white-color;
            border: 1px solid $grey-2 !important;

            span {
                color: $grey-2 !important;
            }
        }
    }

    .dark-button {
        padding: 6px 20px;
        color: $white-color;
        background-color: $black-color;
        border: solid 1px $black-color;
    }

    .export-button {
        background-color: transparent;
        padding: 6px 12px;
        border: 0.25px solid $border-color;
        border-radius: 20px;
    }

    .button-default-outline {
        color: #1F1F1F;
        background-color: $default-button-background-color;
        border: none;
    }

    .button-outline-success {
        border: solid 1px $success-button-background-color;
        padding: 15px;

        &:hover {
            background-color: $success-table-header-background-color;
            color: $success-text;
            border: solid 1px $success-button-background-color;
        }
    }

    .btn-sx {
        padding: 5px !important;
        width: auto !important;
    }

    .btn-text-success {
        color: $success-text;
    }

    .button-outline-success-1 {
        border: solid 1px $success-button-background-color;

        &:hover {
            background-color: $success-table-header-background-color;
            color: $success-text;
            border: solid 1px $success-button-background-color;
        }
    }

    .loading-btn:disabled {
        background-color: $success-button-background-color;

        &:hover {
            background-color: $success-button-hover-background-color;
        }
    }
    .add-button.borderless-button {
        border: none !important;
        background-color: transparent;
        box-shadow: none;
        outline: none;
        color: #30A84B;
        font-size: 12px;
    }
}