.template-table-display-editor {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;

    .topBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px 18px;
        border-bottom: 0.75px solid $grey-2;

        .left {
            display: flex;
            gap: 30px;

            .backIcon {
                cursor: pointer;
            }

            .input-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;

                .templateNameInput {
                    width: 70%;
                    height: 30px;
                    border: none !important;
                    outline: none;
                    padding: 5px;
                }

                .errorTextTemplateName {
                    padding: 0px;
                    color: $error-border-color;
                    font-size: 10px;
                    margin: 0px;
                }
            }

        }


        .topBarButton {
            display: flex;
        }
    }

    .contentBodyContainer {
        .bodyWrapper {
            display: flex;
            flex-direction: column;

            .titleBox {
                display: flex;
                flex-direction: column;
                margin: 30px 0px 0px 20px;

                .titleInput {
                    width: 11%;
                    border: none;
                    outline: none;
                }

                .errorTextTitle {
                    padding: 0px;
                    margin: 0;
                    color: $error-border-color;
                    font-size: 10px;
                }
            }
        }

        display: flex;
        width:100%;
        height:724px;

        .bodySideBar {
            width: 15%;
            border-right: 0.75px solid $grey-2;
            padding: 20px 0;

            .sideBarMenuSelected {
                display: flex;
                align-items: center;
                background-color: $button-background;
                padding: 0px 10px;
                cursor: pointer;
                
                .sideBarContentSelected {
                    font-size: 13px;
                    margin: 20px;
                    color: $title-color;
                    font-family: Poppins;
                }
            }
            
            .sideBarMenu {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0px 10px;

                .sideBarContent {
                    font-size: 13px;
                    margin: 20px;
                    color: $grey-2;
                    font-family: Poppins;
                }
            }
        }

        .contentContainer {
            display: flex;
            justify-content: space-between;
            height: auto;
            // padding-bottom: 10px;
            // border-bottom: 0.5px solid $border-color;

            .addItemIcon {
                margin: 15px;
                padding-left: 15px;
                cursor: pointer;
            }

            .contentWrapper {
                width: 100%;
                padding: 40px 0 20px 0px;

                .title {
                    display: flex;
                    color: $grey-2;
                    margin: 0;
                    padding: 15px 25px;
                    font-family: Poppins;
                    font-size: 13px;
                    border-bottom: 0.5px solid $border-color;
                }

                .deleteIcon {
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                    height: 60px;
                    margin: 15px 0;
                    cursor: pointer;
                    border-bottom: 0.5px solid $border-color;
                }

                .contentBox {
                    margin: 15px 0;
                    height: 60px;
                    border-bottom: 0.5px solid $border-color;

                    .contentImage {
                        width: 40px;
                        height: 40px;
                        position: relative;
                        padding: 5px;
                        align-content: center;
                        margin-left: 30px;
                        background-color: $background-grey;
                        cursor: pointer;

                        .editImage {
                            opacity: 0.5;
                        }

                        .editImageIcon {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                        }
                    }

                    .contentInput {
                        border: none !important;
                        // overflow:visible;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        margin-left: 25px;
                        padding-top: 10px;
                        font-size: 13px;
                        resize: none;
                        align-items: center;
                        height: auto;
                        word-wrap: break-word;
                        /* Standard property for word wrapping */
                        overflow-wrap: break-word;
                    }

                    .errorText {
                        font-size: 10px;
                        padding: 0px;
                        margin: 0px;
                        color: $error-border-color;
                    }

                    .topBarButton {
                        display: flex;
                        gap: 20px;
                        margin-left: auto;
                        margin-right: 50px;

                        .btn .success-button {
                            padding: 6px 26px;
                        }
                    }
                }
            }

            .contentBodyContainer {
                display: flex;
                width: 100%;
                height: 724px;

                .bodySideBar {
                    width: 15%;
                    border-right: 0.75px solid $grey-2;
                    padding: 20px 10px 0 12px;

                    .sideBarMenu {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .sideBarContent {
                            font-size: 13px;
                            margin: 20px;
                            color: $grey-2;
                            font-family: Poppins;
                        }
                    }
                }

                .contentContainer {
                    display: flex;
                    justify-content: flex-start;
                    height: auto;

                    .addItemIcon {
                        margin: 15px;
                        padding-left: 15px;
                        cursor: pointer;
                    }

                    .contentBox {
                        width: 100%;
                        height: 50px;

                        .title {
                            display: flex;
                            color: $grey-2;
                            padding: 25px 25px 15px 25px;
                            font-family: Poppins;
                            font-size: 13px;

                        }

                        .deleteIcon {
                            text-align: center;
                            height: 50px;
                            padding-top: 7px;
                            cursor: pointer;
                        }

                        .contentImage {
                            width: 40px;
                            height: 40px;
                            position: relative;
                            padding: 5px;
                            align-content: center;
                            margin-left: 30px;
                            background-color: $background-grey;
                            cursor: pointer;

                            .editImage {
                                opacity: 0.5;
                            }

                            .editImageIcon {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                            }
                        }

                        .contentInput {
                            border: none !important;
                            outline: none;
                            display: flex;
                            justify-content: center;
                            margin-left: 25px;
                            padding-top: 10px;
                            font-size: 13px;
                            align-items: center;
                        }
                    }
                }

                .contentBody {
                    display: flex;
                    flex-direction: column;

                    .contents {
                        display: flex;
                    }
                }
            }
        }
    }
}