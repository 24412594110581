.authenticate-wrap {
  width: 450px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .authenticate-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .authenticate-header {
      display: flex;
      align-items: center;
      // justify-content: center;
      gap: 10px;

      h1 {
        font-size: 20px;
        font-weight: 600;
        color: $title-color;
      }

      span {
        display: flex;
      }
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      color: $title-color;
      // text-align: center;
    }
  }

  .authenticate-button {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .btn {
      width: max-content;

      .default-button {
        padding: 6px 8px;
      }
    }
  }
}