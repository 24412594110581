.credit-management {

    .title {
        margin: 15px 0;
        font-size: 14px;
        font-weight: 700;
    }

    .content-body {
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 0px 9px #f0f0f0;
        height: 277px;

        .no-data-found {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .no-data-message {
                font-size: 16px;
                font-weight: 600;
            }

            .no-data-description {
                font-size: 14px;
                font-weight: 400;
                color: $grey-2;
            }
        }
    }

    .content-body.with-data {
        height: auto;
        max-height: none;
    }
}

.credit-transfer {
    font-size: 13px;
    font-weight: 500;
    color: $success-text;
    cursor: pointer;
}