.file-upload-box {
    .title {
        font-weight: 500;
        font-size: 14px;
        color: #4b4b4b;
        display: flex;
        align-items: center;
        gap: 2px;
        padding-bottom: 8px;
    }

    .file-upload-session {
        border: dotted 1px $gray-light-color;
        background-color: $button-background;
        border-radius: 8px;
        display: flex;
        gap: 10px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 300px;
        padding: 20px;

        .upload-text {
            font-size: 14px;

            u {
                color: $success-button-background-color;
            }
        }

        .upload-subtext {
            font-size: 14px;
        }

        input {
            display: none;
        }
    }
}

.view-store-image {
    display: flex;
    gap: 20px;
    flex-direction: column;
    img {
        width: 70vw;
        height: 70vh;
    }

    .button-thumbnail {
        display: grid;
        justify-content: flex-end;
        grid-template-columns: auto auto;
        gap: 10px;
        align-items: center;
    }
}