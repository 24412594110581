.user-register-wrap {
    width: 27vw;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .single-row {
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 450px) {
    .user-register-wrap {
        min-width: 307px;

        .single-row {
            grid-template-columns: 100%;
            gap: 15px;
        }
    }
}