.buy-instance-status {

    .text-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        .tooltip-text {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }

        .info-icon {
            font-size: 15px !important;
            color: #9EA3A9 !important;
        }
    }
}