.menu-icon{
    width: auto;
    object-fit: contain;
}

.menu-text{
    margin: 0;
    font-size: 14px;
}

.muiltr-137ul40-MuiButtonBase-root-MuiIconButton-root {
    margin: 0 !important;
}
.muirtl-137ul40-MuiButtonBase-root-MuiIconButton-root {
    margin: 0 !important;
}