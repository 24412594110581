.widget-templates {
    .main-content {
        .content-area {
            .my-content {
                .my-content-area-form {
                    .widget-form {
                        padding: 10px 10px;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        // gap: 1rem;
                        box-shadow: $widget-shadow-color 0px 1px 3px 0px, $widget-shadow 0px 0px 0px 1px;
                        border-radius: 8px;

                        img{
                            width: 241px;
                            height: 129px;
                            border-radius: 6.5px;
                        }
                        p{
                            margin: 0px 20px 20px 20px;
                        }
                        .single-row {
                            display: flex;
                            gap: 45px;
                        }
                    }
                }
            }
        }
    }
}