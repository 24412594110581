.report-by-gender {
    display: flex;
    flex-direction: column;
    background-color: $default-button-background-color;
    border-radius: 6px;
    box-shadow: 0px 1px 8px 1px #0000000D;

    .report-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 10px 15px;

        .header-left {
            display: flex;
            align-items: center;
            gap: 5px;

            .title {
                font-size: 16px;
                font-weight: 500;
                margin: 0;
            }
        }
    }

    // pie chart and horizontal bar chart container
    .pie-chart-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        .pieChart-wrap {
            width: inherit;
            height: 225px;
        }
    }

    .pie-chart-legend {
        border-top: 0.75px dashed #CED0D3;
        padding: 20px;
        display: flex;
        justify-content: center;
        gap: 80px;

        .each-legend {
            display: flex;
            gap: 10px;
            align-items: center;

            .marker {
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }
        }
    }

    .time-chart-wrap {
        width: 100%;
        padding-right: 15px;
    }

    .last-time {
        display: flex;
        gap: 10px;
        padding: 0 15px 15px 15px;

        .last-time-message {
            color: $paragraph-grey-color;
            font-size: 12px;
        }
    }
}
