.saved-templates-popup {
    width: 1000px;
    max-height: 80vh;
    overflow-y: scroll;
    padding-top: 20px;

    .template-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 10px 10px 10px 0;
        justify-content: flex-start;

        .grid-item {
            display: flex;
            flex-direction: column;
            gap: 12px;
            
            .card {
                width: 214px;
                height: 131px;
                border-radius: 6px;
                background-color: $white-color;
                box-shadow: 0px 1px 8px 1px $template-black;
                padding: 6px;
                
                &.active {
                    border: 2px solid $pure-black;
                    box-shadow: 0px 1px 8px 1px $template-black;
                }
                .inner-card {
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                    background-color: #518400;
                }
            }

            .name {
               font-size: 13px; 
            }
        }
    }
}