.content {
  .main-content {
    .content-area {
      .preference-wrap {
        padding: 25px;
        display: grid;
        grid-template-columns: auto 55%;
        justify-content: space-between;
        gap: 30px;

        .preference-content {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .two-factor{
            display: flex;
            align-items: center;
            justify-content: space-between;
           }
          h1 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            color: $black-color;

            padding-bottom: 10px;
            border-bottom: 0.5px solid $border-color;
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $black-color;
            line-height: 25px;
          }
        }

        .preference-image-wrap {
          
          .upload-wrap {
            height: 250px;
            border-radius: 6px;
            border: 0.75px dashed $border-color;
            background-color: $gray-hover-color;

            .upload-container {
              height: inherit;

              .upload-text {
                height: inherit;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 15px;
                text-align: center;
              }

              h2 {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                color: $black-color;


                span {
                  text-decoration: underline;
                  color: $success-text;
                  cursor: pointer;
                }
              }

              p {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                color: $black-color;


                span {
                  font-weight: 500;
                }
              }
            }

            .image-wrap{
              position: relative;

              height: inherit;
              width: inherit;
              border-radius: 6px;
              background-color: $black-color;
              overflow: hidden;

              .display-image{
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              
              .delete-container{
                position: absolute;
                right: 10px;
                top: 10px;

                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: $white-color;

                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }
          }

          .display-images-wrap{
            margin: 15px 0;

            display: flex;
            gap: 5px;
            flex-wrap: wrap;
              
            .upload-box{
              height: 45px;
              width: 45px;
              border-radius: 3px;
              border: 0.75px solid $border-color;
              background-color: $gray-hover-color;

              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            .selected-image{
              height: 45px;
              width: 45px;
              border-radius: 3px;
              border: 0.75px solid $border-color;

              object-fit: cover;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}