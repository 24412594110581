.footer {
    width: 100%;
    height: 3vh;
    background-color: $button-background;

    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}