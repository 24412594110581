.add-content-type-button-wrap {
  padding: 20px 0 0;
  width: 500px;
  max-height: 70vh;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .add-content-type-wrap {

    .add-content-type-tabs {
      display: flex;
      border-bottom: 3px solid $card-border-color;

      .each-tab {
        position: relative;
        padding: 8px 15px 10px;
        cursor: pointer;

        h1 {
          font-size: 14px;
          font-weight: 500;
          color: var(--active-tab-color);
          transition: 0.2s all ease-in-out;
        }

        .active-tab-border {
          width: var(--active-tab-width);
          height: 3px;
          background-color: $success-button-background-color;
          border-radius: 3px;
          position: absolute;
          bottom: -2px;
          left: 0;
          transition: 0.1s all ease-in-out;
        }
      }
    }

    .add-content-type-container {
      padding: 25px 0 20px;

      .add-content-media {
        .add-content-types {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }

        p {
          margin: 25px 0;
          font-size: 13px;
          font-weight: 400;

          span {}
        }
      }
    }
  }

  .add-content-type-button {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .btn {
      width: max-content;
    }
  }
}