.search-input-wrap {
  width: 100%;
  height: 40px;
  padding: 0 10px;

  display: flex;
  align-items: center;
  gap: 10px;

  background-color: $grey-5;
  border-radius: 3px;

  span {}

  .search-input-box {
    font-size: 13px;
    font-weight: 400;
    color: $paragraph-grey-color;

    outline: none;
    border: none;
    background-color: transparent;
  }
}