.content-main-container {
  border-radius: 8px;
  background-color: #fff;

  .grid-view-header {
    margin: 8px;

    .Toggle-button {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;



      .grid-button {
        padding: 6px;
        background-color: $button-background;
      }
    }
  }

  .grid-view-container {

    .card-container {
      padding: 10px 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 18px;

      .card-view {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 20px 20px 20px;
        border-radius: 10px;
        width: 32%;
        height: 50%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        .card-header {
          display: flex;
          gap: 10px;

          .status-button {
            border-radius: 5px;
            padding: 0 5px;
            background-color: #F7FEF8;
            color: #30A84B;
            display: flex;
            align-items: center;
            font-size: 10px;
          }

          .inactive-button {
            border-radius: 5px;
            padding: 0 5px;
            background-color: #FCF7F7;
            color: #E8212E;
            display: flex;
            align-items: center;
            font-size: 10px;
          }
        }

        .device-information {
          width: 100%;
          gap: 30px;
          display: flex;
          flex-wrap: wrap;
          font-size: 11px;

          .item {
            min-height: 41px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .item-label {
              color: #5C646D;
            }
          }

          span {
            font-size: 13px;
          }
        }

        .card-footer {
          display: flex;
          height: 30px;
          align-items: center;

          .footer-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            width: 100%;
            border-top-style: dashed;
            border-top-color: #CED0D3;
            border-top-width: 1px;

            .footer-image {
              width: auto;
            }

            p {
              margin: 0;
              font-size: 12px;
              color: #5C646D;
              width: 100%;
            }

            .more-btn {
              display: flex;
              justify-content: flex-end;
              width: 20px;
              .more-icon{
                font-size: 25px;
                padding-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.signage-form.card {
  display: flex;
  gap: 15px;
  padding: 20px;
  flex-wrap: wrap;
  .input-black {
    flex: 1 1 45%;
  }
}