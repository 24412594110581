.multi-file-upload-wrap {
  width: 55vw;
  overflow-y: scroll;
  padding: 20px 0 20px 0;

  .multi-files-wrap {
    width: 100%;
    padding: 0 10px 0 0;
    max-height: 50vh;
    overflow-x: scroll;

    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .add-more-files {
    margin: 10px 0 0;

    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    span {
      &:first-child {
        display: flex;
      }

      &:last-child {
        font-size: 12px;
        color: $label-text-color;
      }
    }
  }

  .button-wrap {
    margin: 10px 0;

    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .btn {
      width: max-content;
    }
  }
}

.single-file-wrap {
  padding: 17px 20px;
  width: 100%;
  border: 0.5px solid $border-color;
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .single-file-container {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 42px;
      height: 42px;
      border-radius: 3px;
      object-fit: cover;
    }

    .pdf-icon {
      object-fit: contain;
    }

    .single-file-details {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .single-file-name-wrap {
        h1 {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: $title-color;
        }

        input {
          width: 200px;
          outline: none;
          border: none;
          border-bottom: 1px solid $title-color;

          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: $title-color;
        }
      }

      .content-name-error{
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .type-and-size-wrap {
        display: flex;
        align-items: center;
        gap: 15px;

        p {
          margin: 0;
          padding: 4px;
          border-radius: 1px;

          width: max-content;
          font-size: 10px;
          font-weight: 400;
          color: $label-text-color;
          background-color: $green-background-color;
        }

        span {
          font-size: 10px;
          font-weight: 400;
          color: $title-color-2;
        }
      }

      .file-size-error {
        margin: 0;
        font-size: 10px;
        color: $danger-text;
        font-weight: 500;
      }
    }
  }

  .delete-icon-wrap {
    .delete-edit-icon {
      display: flex;
      align-items: center;
      gap: 32px;

      span {
        display: flex;
        cursor: pointer;
      }
    }
  }
}
