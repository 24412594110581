.schedule-layout-content-list-wrap {
  height: 100%;
  padding: 10px 20px;
  border-right: 1px solid $border-color-gray;

  display: grid;
  grid-template-rows: max-content max-content auto;

  .content-list-tabs {
    display: flex;

    .each-tab {
      position: relative;
      padding: 8px 15px 10px;
      cursor: pointer;

      h1 {
        font-size: 14px;
        font-weight: 500;
        color: var(--active-tab-color);
        transition: 0.2s all ease-in-out;
      }

      .active-tab-border {
        width: var(--active-tab-width);
        height: 3px;
        background-color: $success-button-background-color;
        border-radius: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: 0.1s all ease-in-out;
      }
    }
  }

  .content-list-search {
    margin: 10px 0;
  }

  .content-listing {
    height: 100%;
    width: calc(300px - 40px);

    .list-of-contents {
      height: 100%;
      width: calc(300px - 40px);

      .infinite-scroll {
        width: 260px;
        overflow: none;
        overflow-x: hidden;
        overflow-y: auto;

        padding: 0 5px 0 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}