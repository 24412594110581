.content-layout-saved-widgets {
  width: 700px;

  .saved-widgets-wrap {
    padding: 15px 10px 15px 0;
    width: 100%;
    overflow-y: scroll;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    .each-widget {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;

      .each-widget-image-wrap {
        width: inherit;
        height: 105px;
        padding: 7px;
        border-radius: 4px;
        box-shadow: 0px 1px 8px 2px #00000014;

        .each-widget-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      h1 {
        font-size: 13px;
        font-weight: 600;
        color: $title-color;
        margin: 0;
      }
    }
  }

  .saved-widgets-loading {
    width: 100%;
    height: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-content-type-button {
    padding: 15px 0 0;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .btn {
      width: max-content;
    }
  }
}