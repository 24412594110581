.content-status {
    width: 100%;
    padding: 0 40px 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .fileSize-wrap {
        display: flex;
        align-items: center;
        border: solid 0.1px $info-border-color;
        border-radius: 5px;

        .border-right {
            border-right: solid 0.1px $info-border-color;
        }
        .fileSize {
            display: flex;
            gap: 0.5rem;
            padding: 5px 10px;
            align-items: center;

            .fileSize-img {
                width: 32px;
                height: 32px;
            }

            .size-text {

                h3 {
                    margin: 0;
                    font-size: 13px;
                    font-weight: 500;
                    color: $black-color;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    color: $label-color;
                    font-weight: 500;
                }
            }
        }
    }


    .progress {
        display: flex;
        align-items: center;
        gap: 1rem;

        .progress-text {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            h2 {
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: $paragraph-grey-color;
            }

            h3 {
                margin: 0;
                font-size: 13px;
                color: $black-color;
                font-weight: 400;
            }
        }
    }
}