.schedule-view {
    display: flex;
    gap: 10px;
    flex-direction: column;
    height: 73vh;

    .schedule-content-area {
        border-radius: 8px;
        background-color: $white-color;

        .schedule-details {
            display: flex;
            padding: 20px;
            justify-content: space-around;

            .schedule-details-list {
                gap: 20px;
                display: flex;
                align-items: center;
                font-size: 14px;

                .image {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                    }
                }

                .date-text {
                    display: flex;
                    gap: 5px;

                    .image_info {
                        width: 16px;
                        cursor: pointer;
                    }

                }
            }
        }
    }

    .schedule-content {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .my-content {
            height: 100%;
            width: 100%;
            border-radius: 8px;
            background-color: $white-color;

            .my-content-area {
                padding: 0px 20px 20px 20px;

                .contents-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h3 {
                        font-size: 14px;
                    }

                    .tag {
                        background-color: $backend-blue;
                        color: $black-color;
                        padding: 4px 10px;
                        border-radius: 50px;
                        font-size: 13px;
                        display: flex;
                        justify-content: center;
                        gap: 5px;

                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                .contents-body {
                    padding-bottom: 20px;

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;

                        li {
                            display: flex;
                            font-size: 14px;
                            align-items: center;
                            gap: 5px;
                            border: 0.5px solid $gray-color;
                            padding: 3px 8px;
                            border-radius: 4px;
                            cursor: pointer;

                            img {
                                width: 16px;
                            }
                        }
                    }

                    .full-box {
                        a {
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
}

.content-device-view {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .content-view-list {
        display: flex;
        justify-content: space-between;
        width: 400px;
        font-size: 13px;
    }
}

.table-device-view {
    width: 100vh;

    .contents-body {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: center;
            gap: 30px;
            position: relative;
            height: auto;
            width: 100%;

            .schedule-details-list {
                display: flex;
                align-items: center;
                gap: 10px;

                .image {
                    width: 24px;
                    height: 24px;
                }

                .color {
                    width: 20px;
                    height: 20px;
                }

                .available-color {
                    background-color: $available-color;
                }

                .conflict-color {
                    background-color: $conflict-color;
                }
            }
        }

        .table-view {
            overflow-y: scroll;
            table {
                border-collapse: collapse;
                width: 100%;
                position: relative;
                tr {
                    td {
                        border: solid 1px #fff;
                        color: $gray-color;
                        text-align: center;
                        padding: 10px 15px;
                        font-size: 13px;
                        height: 43.43px;
                    }

                    .available-color {
                        background-color: $available-color;
                        color: $available-color;
                    }
    
                    .conflict-color {
                        background-color: $conflict-color;
                        color: $conflict-color;
                    }
                }
            }
        }
    }
}