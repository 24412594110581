.template-container-8 {
    background-color: $pure-black;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;

    .inner-container {
        width: 100%;

        .clothing-template {
            width: 100%;
            height: 100%;
            padding: 2%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .list-container {
                display: flex;
                gap: 25px;
                width: 100%;

                .list-items {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .items {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        text-align: center;

                        .item-image {
                            width: 10vw;
                            height: 128px;
                        }

                        .item-price-wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        }
                    }
                }

                .logo-image {
                    width: 10vw;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}