.dynamic-split-pane {
    margin: 0px;
    height: 92vh;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.layout-container {
    flex: 1;
}

.empty-state {
    width: 100%;
    height: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    color: $success-text;
}

.pane-container {
    height: 100%;
    border: 1px solid $gray-light-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: border 0.3s;
}

.pane-remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: $danger-text;
    cursor: pointer;
}

.add-split-btn {
    margin-top: 5px;
    padding: 5px 10px;
    color: $success-text;
    border: none;
    cursor: pointer;
}

.export-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    color: $white-color;
    border: none;
    cursor: pointer;
    z-index: 1000;
    font-size: 16px;
    border-radius: 5px;
}

.modal {
    position: fixed;
    height: 25vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.modal-content {
    position: relative;
    z-index: 101;
    /* Ensure modal content appears above the overlay */
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-duration: 0.5s;
    /* Slower animation */
    animation-fill-mode: forwards;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 336px;
    height: 50px;
    border-radius: 3px;
    font-size: 14px;
    border: none;
    margin-bottom: 20px;
    gap: 20px;
    cursor: pointer;
    border: 1px solid $card-border-color;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    /* Darken background slightly */
}