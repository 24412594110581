.content-wrap {
    display: flex;
    justify-content: center;

    .main-content {
        width: 75%;

        .content-area {
            background-color: $button-background !important;
            box-shadow: 0px 0px 0px $box-shadow-color !important;

            .my-content {
                .my-content-area-form-1 {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .location-form {
                        background-color: $white-color;
                        box-shadow: 0px 0px 9px $box-shadow-color;
                        border-radius: 8px;
                    }
                }
                .my-content-area-form {
                    background-color: $white-color;
                    box-shadow: 0px 0px 9px $box-shadow-color;
                    border-radius: 8px;
                }
            }
        }
    }
}