.company-form-wrap {
    width: 70vh;
    min-width: 750px;

    .form-container {
        padding: 0 1%;
        max-height: 50vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .single-row {
            display: grid;
            grid-template-columns: 47% 47%;
            justify-content: space-between;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@media screen and (max-width: 450px) {
    .company-form-wrap {
        width: 100%;
        min-width: 0;
        .form-container {
            padding: 25px 1%;

            .single-row {
                grid-template-columns: 100%;
                gap: 15px;
            }
        }
    }
}