.app-container {
    position: relative;
    width: 100%;
  }
  
  .custom-select-box {
    position: relative;
    font-size: 14px;
    .select-input {
      display: flex;
      align-items: center;
      border: 1px solid $gray-light-color;
      padding: 14px;
      margin-top: 10px;
      cursor: pointer;
      justify-content: space-between;
      border-radius: 6px;
      color: #9EA3A9;
      .selected-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
      }
    }
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    top: -300px;
    height: 320px;
    left: 0;
    width: 100%;
    display: flex;
    background: $white-color;
    box-shadow: 0 4px 8px $box-shadow-widget;
  }
  
  .filter-pane, .results-pane {
    padding: 16px;
    width: 20%;
    text-align: center;
  }
  .vertical-line {
    width: 1px; /* Defines the thickness of the line */
    background-color: $border-color; /* Line color */
    height: 95%;
    margin: 10px; /* Full height of the container */
  }

  .filter-option, .result-item {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
  
    .tick {
      margin-left: auto;
      color: $success-text;
      font-weight: bold;
      padding-right: 10px;
    }
  }
  
  .tag {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid $gray-light-color;
    border-radius: 16px;
    background: $grey-5;
  
    .remove {
      cursor: pointer;
      color: $danger-text;
    }
  
    .icon {
      width: 12px;
      height: 12px;
      display: inline-block;
  
      &.male {
        border-radius: 50%;
      }
  
      &.female {
        border-radius: 50%;
      }
    }
  }
  