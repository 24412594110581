@font-face {
    font-family: 'Urbanist';
    src: url(../../../assets/fonts/urbanist/Urbanist-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Urbanist';
    src: url(../../../assets/fonts/urbanist/Urbanist-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Urbanist';
    src: url(../../../assets/fonts/urbanist/Urbanist-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Great Vibes';
    src: url(../../../assets/fonts/greatVibes/GreatVibes-Regular.ttf) format('truetype');
    font-weight: 500;
}

.template-container-12 {
    width: 100%;
    height: 100vh;
    padding: 5px;

    .main-container {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .left-top-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 30vw;

            img {
                width: 100%;
                // height: 100%;
            }
        }

        .right-top-image {
            position: absolute;
            top: 0;
            right: 0;
            width: 30vw;

            img {
                width: 100%;
                // height: 100%;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .person-image {
                width: 30vw;
                border-radius: 50%;
                border: 7px solid #ECE2E0;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .content-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                .name-box {
                    padding: 7px 0px;
                    width: 42%;
                    background-color: #001C3C;
                    border-radius: 20px;
                }
            }

            .content-description {
                margin-top: 10px;
                font-weight: 500;
                width: 60%;
            }
        }

        .left-bottom-image {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 10vw;
            // height: 130px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .right-bottom-image {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 10vw;
            // height: 130px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 998px) {
    .template-container-12 {
        .main-container {
            .left-top-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 30vw;
                // height: 115px;
            }

            .right-top-image {
                position: absolute;
                top: 0;
                right: 0;
                width: 30vw;
                // height: 115px;
            }
        }
    }
}