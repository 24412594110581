$white-color: #fff;
$black-color: #212B36;
$gray-color: #8e8e8e;
$gray-light-color: #ccc;
$gray-hover-color: #FAFAFA;
$gray-hover-color-1: #f5f5f5;
$yellow-color: #F19702;
$blue-color: #1A5EEC;
$label-color: #212B36;
$dark-gray-color: #5c5858;
$backend-blue: #f1f5f8;
$available-color: #73AA4C;
$conflict-color: #AA4C4C;
$border-color: #CED0D3;
$border-color-gray: #9EA3A9;
$button-background: #F9FAFB;
$card-border-color: #CED0D3;
$box-shadow-color: #f0f0f0;
$box-shadow-widget:#0000001a;
$widget-shadow-color:#00000005;
$widget-shadow:#1b1f2326;
$button-gray-background-color: #00000080;

// Success
$success-button-background-color: #30A84B;
$success-text: #30A84B;
$success-button-hover-background-color: #198431;
$success-table-header-color: #198431;
$success-table-header-background-color: #F7FEF8;

// Danger
$danger-button-background-color: #E8212E;
$danger-button-hover-background-color: #b50511;
$danger-text: #E8212E;
$danger-background: #FEF7F7;
$danger-text-secondary: #B92520;
$suspend-danger-background-color: #FEF3F2;

// Default
$default-button-background-color: #ffffff;
$default-button-hover-background-color: #1F1F1F;

// Info
$info-button-background-color: #1A5EEC;
$info-button-hover-background-color: #023dba;
$info-border-color: #1C75BC;
$bullet-point-color: #0A7BCC;

// error
$error-border-color: #E8212E;

// font-color
$title-color: #212B36;
$title-color-2: #5C646D;
$paragraph-grey-color: #717880;
$paragraph-blue-color: #1C75BC;
$store-size-color: #5C646D;
$grey-2: #9EA3A9;
$grey-5: #F9FAFB;
$label-text-color: #118D57;

// Background Color
$blue-background-color: #F7FBFE;
$grey-background-color: #F6F6F6;
$background-grey:#EEEEEE;
$glass-black-background: #212B36D4;
$green-background-color: #ECFDF3;

//PosItEasy
$edit-btn-purple-color: #5A0A45;