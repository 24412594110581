.popup {
    .popup-list {
        display: none;
        position: relative;
        border: solid 0.5px $gray-color;
        padding: 10px;
        background: $white-color;
        box-shadow: 10px 0px 9px 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        max-height: 30vh;
        width: auto;
        overflow-y: scroll;

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            li {
                font-size: 13px;
                padding-top: 10px;
            }
        }
    }
    &:hover {
        cursor: pointer;
        .popup-list {
            display: block;
        }

    }
}