.template-custom-banner {
    width: 100%;
    height: auto;
    background: linear-gradient(98deg, $box-shadow-green 0%, $box-shadow-green 90%);
    margin-top: 4%;

    .custom-board-content {
        float: right;
        width: 20px;
        height: 20px;
        color: $gray-color;
        margin: 10px;
        margin-bottom: 0px;

    }

    .custom-board-content-container {
        display: flex;
        flex-direction: column;
        width: inherit;
        margin-left: 3%;

        .top-content {
            font-size: 45px;
            color: $white-color;
            font-weight: 500;
            margin: 0px;
        }

        .mid-content {
            color: $white-color;
            font-weight: 400;
            font-size: 15px;
        }

        .mail-icon-container {
            display: flex;
            color: $white-color;
            align-items: center;
            width: 20%;
            height: 20px;

            .mail-icon {
                width: 30px;
                height: 30px;
                color: $white-color;
            }
        }
    }

    .content-board-image-container {
        width: 40%;
        height: inherit;
        object-fit: fill;
        margin: auto;
    }
}