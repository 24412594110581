.calender-schedule-form {
  width: 60vw;
  max-height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-field-row {
    display: flex;
    gap: 10px;

    .input-black {
      gap: 8px;
    }

    .form-field-1 {
      display: flex;
      gap: 10px;
    }
  }

  .form-field-button {
    width: max-content;
  }

  .form-select-field {
    .input-black {
      .select-input-box {
        .list-chips {
          flex: 8 1;
        }
      }
    }
  }

  .edit-form-button-group {
    display: flex;
    gap: 20px;
    .cancel-group{
      display: flex;
      gap: 8px;
      .cancel-button{
        width: 20px;
        height: 20px;
      }
      .btn{
        padding-top: 3.5px;
      }
    }
    .edit-form-button-group-right{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }

  }
}