.contents-list {
    width: 900px;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: scroll;
    // max-height: 580px;
    .contents-list-header {
        display: flex;
        gap: 20px;
        height: 36px;
    }
    .table-response {
        padding-bottom: 15px;
    }

    .schedule-details {
        padding: 0px 20px 20px 20px;
        display: flex;
        flex-direction: row;
        gap: 50px;
        .schedule-details-list {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            .image {
                width: 24px;
                height: 24px;

                img {
                    width: 100%;
                }
            }

            .schedule-date-view{
                display: flex;
                align-items: center;
            }

        }
    }
}